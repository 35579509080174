import { RootDiv } from 'src/components/root'

import { Balances } from './components/balance'
import { CheckoutRoot } from './components/checkout-root'
import { Input } from './components/input'
import { TextSubtitle } from './components/text/subtitle'
import { TextTitle } from './components/text/title'

interface ICheckoutContent {
  Root: typeof RootDiv
  Balances: typeof Balances
  Text: {
    Title: typeof TextTitle
    Subtitle: typeof TextSubtitle
  }
  Input: typeof Input
}

const Content: ICheckoutContent = {
  Root: RootDiv,
  Balances,
  Text: {
    Title: TextTitle,
    Subtitle: TextSubtitle,
  },
  Input,
}

interface ICheckout {
  Root: typeof CheckoutRoot
  Content: typeof Content
}

const Checkout: ICheckout = {
  Root: CheckoutRoot,
  Content,
}

export default Checkout
