import {
  dayjs,
  Divider,
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { OrderSummaryBenefits } from './components/benefit/benefit'
import { OrderSummaryDepositDate } from './components/depositDate/depositDate'
import { OrderSummaryFee } from './components/fee/fee'
import { OrderSummary as Summary } from './components/summary'
import { OrderSummaryTotalAmount } from './components/total-amount/total-amount'
import { IOrderSummaryProps } from './order-summary'
import {
  IOrderSummaryFeeProps,
  // IOrderSummaryDiscountProps,
} from './order-summary.interface'

interface IOrderSummaryDetailedProps extends IOrderSummaryProps {
  fees?: IOrderSummaryFeeProps[]
  // discounts: IOrderSummaryDiscountProps[];
  depositDate: dayjs.Dayjs
  isTopup?: boolean
  showEmployeesOnClick: () => void
}

export const OrderSummaryDetailed = ({
  benefits,
  totals,
  // discounts,
  fees,
  depositDate,
  employeesCount,
  isTopup,
  showEmployeesOnClick,
}: IOrderSummaryDetailedProps) => {
  const theme = useTheme()
  return (
    <>
      <Divider orientation="horizontal" />
      {benefits?.length && (
        <>
          <OrderSummaryBenefits isTopup={isTopup} benefits={benefits} />
          <Divider orientation="horizontal" />
        </>
      )}

      {totals?.length && (
        <>
          <OrderSummaryTotalAmount totals={totals} />
          <Divider orientation="horizontal" />
        </>
      )}
      {fees?.length && (
        <>
          <OrderSummaryFee fees={fees} />
          <Divider orientation="horizontal" />
        </>
      )}

      {/* <OrderSummaryDiscount discounts={discounts} /> */}
      {/* <Divider orientation="horizontal" /> */}
      <Grid.Container gap={theme.spacings.s} direction={'row'}>
        <OrderSummaryDepositDate depositDate={depositDate} />
        <Summary.Root>
          <Summary.ListTitle>
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral20}
            >
              {t(`order.review.cardGrid.orderSummary.employeesCount`)}
            </Typography>
          </Summary.ListTitle>
          <Summary.ItemRoot>
            <Icons name="IconUsers" size={24} />
            <Typography variant="body4" variantColor={theme.colors.neutral30}>
              {employeesCount}
            </Typography>
            <LinkButton
              style={{ fontSize: `calc(${theme.spacings.xs2} - 2px)` }}
              variant="default"
              onClick={showEmployeesOnClick}
            >
              {t('order.checkout.orderSummary.orderEmployeesButton')}
            </LinkButton>
          </Summary.ItemRoot>
        </Summary.Root>
      </Grid.Container>
    </>
  )
}
