import Grid, { GridProps } from '@mui/material/Grid'
import React from 'react'

export interface ContainerGridProps extends GridProps {}

export const ContainerGrid: React.FC<ContainerGridProps> = ({
  children,
  ...props
}) => {
  return (
    <Grid container direction={'row'} minWidth={'100%'} {...props}>
      {children}
    </Grid>
  )
}
