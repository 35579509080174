import { dayjs, IconTypes } from '@flash-tecnologia/hros-web-ui-v2'
import { GetOrderSummaryResponse } from 'backend/src/services'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { processEnv } from 'src/utils/env'
import { create } from 'zustand'

import { Benefit, Employee, Group } from '../steps/employee-selection/types'
import { updateBenefits } from './helpers/update-benefits'

export const CREDIT_DAYS_DEFAULT = 30

export enum Step {
  EMPLOYEE_SELECTION = 0,
  PAYMENT_SETUP = 1,
  REVIEW_DETAILS = 2,
  ORDER_COMPLETED = 3,
}

type Order = {
  id?: string
  paymentMethod?: PaymentMethodEnum
  depositDate?: dayjs.Dayjs
  dueDate?: dayjs.Dayjs
  cutoffDate?: dayjs.Dayjs
  receiptDescription?: string
  pixCode?: string
}

export type FlashCashBalance = {
  label: 'plastic' | 'virtual'
  value: number
}

export type StepNavigationButtonOptions = {
  text: string
  onClick?: () => void
  isLoading?: boolean
  showIcon?: boolean
  iconName?: IconTypes
  iconPosition?: 'left' | 'right'
  disabled?: boolean
}

type UpdateEmployeeBenefitValue = {
  employeeId: string
  updatedBenefits: Benefit[]
}

type NewOrderStoreState = {
  currentStep: Step
  groups: Group[]
  isGroupsInitialized: boolean
  selectedEmployees: Employee[]
  order: Order | null
  orderSummary: GetOrderSummaryResponse | null
  isTopupCredit: boolean
  continueButtonOptions: StepNavigationButtonOptions | null
  backButtonOptions: StepNavigationButtonOptions | null
  flashCashBalances: FlashCashBalance[] | []
  creditDays: number
}

type NewOrderStoreSetters = {
  setGroups: (group: Group[]) => void
  markGroupsAsInitialized: () => void
  updateEmployeeBenefitValue: (employee: UpdateEmployeeBenefitValue) => void
  updateSelectedEmployeesBenefitValue: (updatedBenefits: Benefit[]) => void
  setBenefitsForSelectedEmployees: (
    employees: Employee[],
    updatedBenefits: Benefit[],
  ) => void
  setSelectedEmployees: (employees: Employee[]) => void
  setOrder: (order: Partial<Order>) => void
  setOrderSummary: (summary: GetOrderSummaryResponse | null) => void
  resetOrder: () => void
  setContinueButtonOptions: (options: StepNavigationButtonOptions) => void
  setBackButtonOptions: (options: StepNavigationButtonOptions) => void
  nextStep: () => void
  previousStep: () => void
  setFlashCashBalances: (balances: FlashCashBalance[]) => void
  setIsTopupCredit: (isTopup: boolean) => void
  setCreditDays: (days: number) => void
  resetStore: () => void
}

type NewOrderStore = NewOrderStoreState & NewOrderStoreSetters

const initialState: NewOrderStoreState = {
  groups: [],
  isGroupsInitialized: false,
  selectedEmployees: [],
  order: null,
  orderSummary: null,
  continueButtonOptions: null,
  backButtonOptions: null,
  currentStep: Step.EMPLOYEE_SELECTION,
  flashCashBalances: [],
  isTopupCredit: false,
  creditDays: CREDIT_DAYS_DEFAULT,
}

export const useNewOrderStore = create<NewOrderStore>((set) => ({
  ...initialState,
  setGroups: (groups) => {
    set({ groups })
  },
  markGroupsAsInitialized: () => {
    set({ isGroupsInitialized: true })
  },
  updateEmployeeBenefitValue: ({
    employeeId,
    updatedBenefits,
  }: UpdateEmployeeBenefitValue) => {
    set((state) => {
      const updateEmployee = (employee: Employee) =>
        employee.id === employeeId
          ? updateBenefits(employee, updatedBenefits)
          : employee

      // Updates groups with their respective totals
      const updatedGroups = state.groups.map((group) => {
        const isEmployeeInGroup = group.employees.some(
          (employee) => employee.id === employeeId,
        )

        if (!isEmployeeInGroup) {
          return group
        }

        const updatedEmployees = group.employees.map(updateEmployee)

        const newBenefits = updatedBenefits.filter(
          (updatedBenefit) =>
            !group.benefits.some(
              (benefit) => benefit.name === updatedBenefit.name,
            ),
        )

        const updatedGroupBenefits = [...group.benefits, ...newBenefits]

        const updatedGroupTotal = updatedEmployees.reduce(
          (total, employee) => total + employee.total,
          0,
        )

        return {
          ...group,
          employees: updatedEmployees,
          benefits: updatedGroupBenefits,
          total: updatedGroupTotal,
        }
      })

      // Updates selected employees
      const updatedSelectedEmployees =
        state.selectedEmployees.map(updateEmployee)

      return {
        groups: updatedGroups,
        selectedEmployees: updatedSelectedEmployees,
      }
    })
  },
  updateSelectedEmployeesBenefitValue: (updatedBenefits: Benefit[]) => {
    set((state) => {
      const updateEmployeeBenefits = (employee: Employee) =>
        updateBenefits(employee, updatedBenefits)

      // Updates selected employees
      const updatedSelectedEmployees = state.selectedEmployees.map(
        updateEmployeeBenefits,
      )

      // Updates groups with their respective totals
      const updatedGroups = state.groups.map((group) => {
        const hasSelectedEmployees = group.employees.some((employee) =>
          state.selectedEmployees.some(
            (selectedEmployee) => selectedEmployee.id === employee.id,
          ),
        )

        if (!hasSelectedEmployees) {
          return group
        }

        const updatedEmployees = group.employees.map((employee) => {
          const isSelected = state.selectedEmployees.some(
            (selectedEmployee) => selectedEmployee.id === employee.id,
          )

          return isSelected ? updateEmployeeBenefits(employee) : employee
        })

        const newBenefits = updatedBenefits.filter(
          (updatedBenefit) =>
            !group.benefits.some(
              (benefit) => benefit.name === updatedBenefit.name,
            ),
        )

        const updatedGroupBenefits = [...group.benefits, ...newBenefits]

        const updatedGroupTotal = updatedEmployees.reduce(
          (total, employee) => total + employee.total,
          0,
        )

        return {
          ...group,
          employees: updatedEmployees,
          benefits: updatedGroupBenefits,
          total: updatedGroupTotal,
        }
      })

      return {
        selectedEmployees: updatedSelectedEmployees,
        groups: updatedGroups,
      }
    })
  },
  setBenefitsForSelectedEmployees(employees, updatedBenefits) {
    set((state) => {
      // Updates the benefits of each provided employee
      const updateEmployeeBenefits = (employee: Employee) =>
        updateBenefits(employee, updatedBenefits)

      // Creates an updated list of selectedEmployees by merging with the provided ones
      const updatedSelectedEmployees = state.selectedEmployees.map(
        (employee) => {
          const isUpdated = employees.some(
            (updatedEmployee) => updatedEmployee.id === employee.id,
          )

          return isUpdated ? updateEmployeeBenefits(employee) : employee
        },
      )

      // Updates the groups with the updated selected employees
      const updatedGroups = state.groups.map((group) => {
        // Checks if the group contains selected employees for update
        const hasSelectedEmployees = group.employees.some((employee) =>
          employees.some(
            (selectedEmployee) => selectedEmployee.id === employee.id,
          ),
        )

        if (!hasSelectedEmployees) {
          return group
        }

        // Updates only the selected employees in the group
        const updatedEmployees = group.employees.map((employee) => {
          const isSelected = employees.some(
            (selectedEmployee) => selectedEmployee.id === employee.id,
          )
          return isSelected ? updateEmployeeBenefits(employee) : employee
        })

        // Adds the new benefits to the group, if they do not already exist
        const newBenefits = updatedBenefits.filter(
          (updatedBenefit) =>
            !group.benefits.some(
              (benefit) => benefit.name === updatedBenefit.name,
            ),
        )

        const updatedGroupBenefits = [...group.benefits, ...newBenefits]

        // Recalculates the total of the group with the new values
        const updatedGroupTotal = updatedEmployees.reduce(
          (total, employee) => total + employee.total,
          0,
        )

        return {
          ...group,
          employees: updatedEmployees,
          benefits: updatedGroupBenefits,
          total: updatedGroupTotal,
        }
      })

      return {
        groups: updatedGroups,
        selectedEmployees: updatedSelectedEmployees,
      }
    })
  },
  setSelectedEmployees: (employees) => {
    set({ selectedEmployees: employees })
  },
  setOrder: (order) => {
    set((oldState) => ({ order: { ...oldState.order, ...order } }))
  },
  resetOrder: () => {
    set({ order: initialState.order })
  },
  setOrderSummary: (summary) => {
    set({ orderSummary: summary })
  },
  setContinueButtonOptions: (options) => {
    set({ continueButtonOptions: options })
  },
  setBackButtonOptions: (options) => {
    set({ backButtonOptions: options })
  },
  nextStep: () => {
    const stepMapping = {
      [Step.EMPLOYEE_SELECTION]: Step.PAYMENT_SETUP,
      [Step.PAYMENT_SETUP]: Step.REVIEW_DETAILS,
      [Step.REVIEW_DETAILS]: Step.ORDER_COMPLETED,
      [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
    }

    set((oldState) => ({
      currentStep: stepMapping[oldState.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    }))
  },
  previousStep: () => {
    const stepMapping = {
      [Step.EMPLOYEE_SELECTION]: Step.EMPLOYEE_SELECTION,
      [Step.PAYMENT_SETUP]: Step.EMPLOYEE_SELECTION,
      [Step.REVIEW_DETAILS]: Step.PAYMENT_SETUP,
      [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
    }

    set((oldState) => ({
      currentStep: stepMapping[oldState.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    }))
  },
  setFlashCashBalances: (balances) => {
    set({ flashCashBalances: balances })
  },
  setIsTopupCredit: (isTopup) => {
    set({ isTopupCredit: isTopup })
  },
  setCreditDays(days) {
    set({ creditDays: days })
  },
  resetStore: () => {
    set({ ...initialState })
  },
}))

if (processEnv.BUILD_ENV === 'development') {
  useNewOrderStore.subscribe((state) => {
    console.log('state updated (useNewOrderStore)', state)
  })
}
