export enum DepositTimeUnitEnum {
  DAY = 'day',
  MONTH = 'month',
}

export type Benefit = {
  id: string
  value: number
  name: string
  url: string
  topupDepositEnabled: boolean
  depositTimeUnit: DepositTimeUnitEnum
}

export type Employee = {
  id: string
  groupId: string
  name: string
  document: string
  benefits: Benefit[]
  total: number
}

export type Group = {
  id: string
  name: string
  benefits: Omit<Benefit, 'id' | 'value'>[]
  total: number
  employees: Employee[]
}
