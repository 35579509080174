import { Reminder } from 'src/components/reminder'

import { ConfirmationButtons } from './components/buttons'
import { InfoItem } from './components/info-item'

export interface IConfirmationContent {
  Info: typeof InfoItem
  Buttons: typeof ConfirmationButtons
  Reminder: typeof Reminder
}

export const ConfirmationContent: IConfirmationContent = {
  Info: InfoItem,
  Buttons: ConfirmationButtons,
  Reminder,
}
