import { BalanceRoot } from './balance-root'
import { BalancesTag } from './balance-tag'
import { BalancesTitle } from './balance-title'

interface IBalances {
  Root: typeof BalanceRoot
  Title: typeof BalancesTitle
  Tag: typeof BalancesTag
}

export const Balances: IBalances = {
  Root: BalanceRoot,
  Title: BalancesTitle,
  Tag: BalancesTag,
}
