import { Tag } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface IOptionalTagProps {}

const StyledTag = styled(Tag)`
  align-self: center;
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs4}`};
  line-height: ${({ theme }) => theme.spacings.xs5};
  font-size: 10px;
`

export const InputOptionalTag: React.FC<IOptionalTagProps> = () => {
  const { t } = useTranslation()
  return (
    <StyledTag variant="gray" as="label">
      {t('optionalTag')}
    </StyledTag>
  )
}
