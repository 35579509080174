import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

export interface IReceiptDescriptionProps {
  disabled?: boolean
  value?: string
  onChange: (_event: React.FormEvent<HTMLTextAreaElement>) => void
}

export const ReceiptDescription: React.FC<IReceiptDescriptionProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation()
  const { trackEvent } = useEventTracking()

  const onClick = () => {
    trackEvent({
      name: 'FlashOS_BenefitOrders_Checkout_Order_Description_Click',
    })
  }

  return (
    <Checkout.Content.Root>
      <Checkout.Content.Input.Root>
        <Checkout.Content.Input.Label
          optionalTag={Checkout.Content.Input.OptionalTag}
        >
          {t('order.receiptDescription.label')}
        </Checkout.Content.Input.Label>
        <Checkout.Content.Input.TextField
          disabled={disabled}
          id="receiptDescriptionInput"
          maxLength={100}
          customInfo={
            <Typography variant="caption">
              {t('order.receiptDescription.helperText', {
                max: 100,
              })}
            </Typography>
          }
          placeholder={t('order.receiptDescription.placeholder')}
          value={value}
          onChange={onChange}
          onClick={onClick}
        />
      </Checkout.Content.Input.Root>
    </Checkout.Content.Root>
  )
}
