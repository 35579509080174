import { Emoji } from 'src/components/emoji'
import styled from 'styled-components'

export const BenefitsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.xs2};
`

export const BenefitsListWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: ${({ theme }) => theme.spacings.xs2};
`

export const BenefitItemWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    display: flex;
    align-items: center;

    gap: ${({ theme }) => theme.spacings.xs4};

    > p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 130px;
    }
  }
`

export const BenefitItemEmojiStyled = styled(Emoji)`
  background-color: transparent;
  padding: 0;
  border-radius: 0;
`
