import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { Grid } from 'src/components/grid'
import styled from 'styled-components'

export const TitleStyled = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`

export const GridContainerStyled = styled(Grid.Container)`
  grid-template-columns: calc(100% - 75%) auto;
`
