import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Focused } from 'src/components/focused'
import { useTheme } from 'styled-components'

import { useNewOrderPage } from './hooks/use-new-order-page'
import { useNewOrderStore } from './store/use-new-order-store'
import { TitleStyled } from './styles'

export default function NewOrderPage() {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    activeStep,
    StepRender,
    breadcrumb,
    steps,
    startActions,
    endActions,
  } = useNewOrderPage()

  const { resetStore } = useNewOrderStore()

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [resetStore])

  return (
    <Focused
      activeStep={activeStep}
      breadcrumb={breadcrumb}
      steps={steps}
      footer={{
        startActions,
        endActions,
      }}
    >
      <TitleStyled
        variant="headline6"
        weight={700}
        variantColor={theme.colors.neutral20}
      >
        {t('newOrder.title')}
      </TitleStyled>

      <StepRender />
    </Focused>
  )
}
