import styled from 'styled-components'

export interface ISummaryItemProps {
  children: JSX.Element[] | JSX.Element
}
const SummaryItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SummaryItem: React.FC<ISummaryItemProps> = ({
  children,
}: ISummaryItemProps) => <SummaryItemContainer>{children}</SummaryItemContainer>
