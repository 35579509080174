import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { InputOptionalTag } from './input-optional-tag'

export interface ILabelProps {
  children: string | string[]
  optionalTag?: React.ElementType<React.ComponentProps<typeof InputOptionalTag>>
}

const StyledLabel = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`

export const InputLabel: React.FC<ILabelProps> = ({
  children,
  optionalTag: OptionalTag,
}) => {
  const theme = useTheme()
  return (
    <StyledLabel
      variant="body3"
      weight={700}
      variantColor={theme.colors.neutral20}
    >
      {children}
      {OptionalTag && <OptionalTag />}
    </StyledLabel>
  )
}
