import { useCallback, useState } from 'react'

export type Pagination = {
  pageNumber: number
  pageSize: number
}

export function usePagination(initialPagination?: Partial<Pagination>) {
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: initialPagination?.pageNumber ?? 1,
    pageSize: initialPagination?.pageNumber ?? 10,
  })

  const getPaginatedData = useCallback(
    <T>(data: T[]) => {
      const startIndex = (pagination.pageNumber - 1) * pagination.pageSize
      const endIndex = pagination.pageNumber * pagination.pageSize

      return data.slice(startIndex, endIndex)
    },
    [pagination.pageNumber, pagination.pageSize],
  )

  return {
    pagination,
    setPagination,
    getPaginatedData,
  }
}
