import { dayjs, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { OrderSummaryDetailed } from '../../../../../../../checkout/presenters/order-summary/order-summary-detailed'
import { useNewOrderStore } from '../../../../store/use-new-order-store'
import { useOrderReviewSummary } from './hooks/use-order-review-summary'

type OrderReviewSummaryPros = {
  setShowEmployeesOrderModal: Dispatch<SetStateAction<boolean>>
}

export const OrderReviewSummary = ({
  setShowEmployeesOrderModal,
}: OrderReviewSummaryPros) => {
  const theme = useTheme()
  const {
    handleShowEmployeesOrderModal,
    benefits,
    fees,
    totals,
    employeesCount,
  } = useOrderReviewSummary({
    setShowEmployeesOrderModal,
  })
  const { order, isTopupCredit } = useNewOrderStore()

  return (
    <Grid.Item
      xs={12}
      xl={8}
      display={'flex'}
      flexDirection={'column'}
      rowGap={theme.spacings.xs}
    >
      <Typography variant="headline8" variantColor={theme.colors.neutral30}>
        {t(`order.review.cardGrid.orderSummary.title`)}
      </Typography>
      <OrderSummaryDetailed
        isTopup={isTopupCredit}
        benefits={benefits}
        fees={fees}
        totals={totals}
        employeesCount={employeesCount}
        showEmployeesOnClick={handleShowEmployeesOrderModal}
        depositDate={order?.depositDate as dayjs.Dayjs}
      />
    </Grid.Item>
  )
}
