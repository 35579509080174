import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'
import { useTheme } from 'styled-components'

export interface IBalancesTitleProps {
  children: string
}

export const BalancesTitle: React.FC<IBalancesTitleProps> = (props) => {
  const theme = useTheme()
  return (
    <Typography
      variant="body3"
      weight={700}
      variantColor={theme.colors.neutral30}
    >
      {props.children}
    </Typography>
  )
}
