import { Benefit, Employee } from '../../steps/employee-selection/types'

export const updateBenefits = (
  employee: Employee,
  updatedBenefits: Benefit[],
): Employee => {
  const updatedBenefitsList = employee.benefits.map((benefit) => {
    const benefitUpdate = updatedBenefits.find(
      (updatedBenefit) => updatedBenefit.id === benefit.id,
    )

    return benefitUpdate ? { ...benefit, value: benefitUpdate.value } : benefit
  })

  const newBenefits: Benefit[] = updatedBenefits
    .filter(
      (updatedBenefit) =>
        !employee.benefits.some((benefit) => benefit.id === updatedBenefit.id),
    )
    .map((newBenefit) => ({
      id: newBenefit.id,
      name: newBenefit.name,
      url: newBenefit.url,
      depositTimeUnit: newBenefit.depositTimeUnit,
      value: newBenefit.value,
      topupDepositEnabled: newBenefit.topupDepositEnabled,
    }))

  const finalBenefitsList = [...updatedBenefitsList, ...newBenefits]

  const updatedTotal = finalBenefitsList.reduce(
    (accumulator, benefit) => accumulator + benefit.value,
    0,
  )

  return { ...employee, benefits: finalBenefitsList, total: updatedTotal }
}
