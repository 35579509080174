import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { Grid } from 'src/components/grid'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

import { OrderStatusEnum } from '../../../../../../components/order-confirmation-card/components/header/components/tags/order-status-tag'
import { IOrderMethodConfirmation } from '../../order-method-confirmation.interface'
import { OrderHeader } from '../order-header'
import { OrderInfos } from '../order-infos/order-infos'
import { OrderLinks as OrderLinksComponent } from '../order-links'
import { OrderReminder } from '../order-reminder'
import { OrderTitle } from '../order-title'

export interface IFlashCashConfirmationProps extends IOrderMethodConfirmation {}

export const FlashCashConfirmation: React.FC<IFlashCashConfirmationProps> = ({
  amount,
  orderId,
  depositDate,
  openNewTab,
  OrderLinks = OrderLinksComponent,
}) => {
  const theme = useTheme()
  return (
    <Grid.Container container rowGap={{ xs: theme.spacings.s }}>
      <Grid.SideGrid item xs={12} xl={3} pr={{ xl: theme.spacings.s }}>
        <Typography variant="headline7">
          {t(
            `order.confirmation.sideGrid.${PaymentMethodEnum.FLASH_CASH}.title`,
          )}
        </Typography>
        <Typography variant="body3">
          {t(
            `order.confirmation.sideGrid.${PaymentMethodEnum.FLASH_CASH}.subtitle`,
          )}
        </Typography>
      </Grid.SideGrid>
      <Grid.CardGrid item xs={12} xl={9}>
        <OrderHeader
          status={OrderStatusEnum.PAID}
          paymentMethod={PaymentMethodEnum.FLASH_CASH}
        />
        <OrderTitle
          paymentMethod={PaymentMethodEnum.FLASH_CASH}
          orderId={orderId}
        />
        <OrderInfos amount={amount} depositDate={depositDate} />
        <OrderLinks openNewTab={openNewTab} />
        <OrderReminder
          depositDate={depositDate}
          paymentMethod={PaymentMethodEnum.FLASH_CASH}
        />
      </Grid.CardGrid>
    </Grid.Container>
  )
}
