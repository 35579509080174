import { LinkButton, Modal } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  row-gap: ${({ theme }) => theme.spacings.xs2};
`

export const IconWrapperStyled = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: ${({ theme }) => theme.spacings.xl5};
  height: ${({ theme }) => theme.spacings.xl5};

  border-radius: ${({ theme }) => theme.borders.radius.circular};

  background-color: ${({ theme }) => theme.colors.secondary95};

  color: ${({ theme }) => theme.colors.secondary50};
`

export const WrapperTitleStyled = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;

  row-gap: ${({ theme }) => theme.spacings.xs5};
`

export const ButtonCancelStyled = styled(LinkButton)`
  align-self: center !important; // TODO needs to be adjusted in the future using DS
`
