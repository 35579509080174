import { CardGrid } from './components/card-grid'
import { ContainerGrid } from './components/container-grid'
import { GridItem } from './components/item'
import { SideGrid } from './components/side-grid'
export interface ICheckoutGridProps {
  Container: typeof ContainerGrid
  CardGrid: typeof CardGrid
  SideGrid: typeof SideGrid
  Item: typeof GridItem
}

export const Grid: ICheckoutGridProps = {
  Container: ContainerGrid,
  CardGrid,
  SideGrid,
  Item: GridItem,
}
