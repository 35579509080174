import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

import {
  BilletCheckout,
  IBilletCheckoutProps,
} from './components/billet/billet-checkout'
import {
  FlashCashCheckout,
  IFlashCashCheckoutProps,
} from './components/flash-cash/flash-cash-checkout'
import { IPixCheckoutProps, PixCheckout } from './components/pix/pix-checkout'

export interface IPaymentMethodCheckoutMap {
  [PaymentMethodEnum.BILLET]: React.FC<IBilletCheckoutProps>
  [PaymentMethodEnum.PIX]: React.FC<IPixCheckoutProps>
  [PaymentMethodEnum.FLASH_CASH]: React.FC<IFlashCashCheckoutProps>
}

const paymentMethodCheckoutMap: IPaymentMethodCheckoutMap = {
  [PaymentMethodEnum.BILLET]: BilletCheckout,
  [PaymentMethodEnum.FLASH_CASH]: FlashCashCheckout,
  [PaymentMethodEnum.PIX]: PixCheckout,
}

type PaymentMethodCheckoutProps =
  | IBilletCheckoutProps
  | IPixCheckoutProps
  | IFlashCashCheckoutProps

export const CheckoutMethod: React.FC<PaymentMethodCheckoutProps> = ({
  paymentMethod,
  ...rest
}) => {
  const PaymentMethodCheckout = paymentMethodCheckoutMap[paymentMethod]

  return <PaymentMethodCheckout {...rest} paymentMethod={paymentMethod} />
}
