import { Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { formatCurrency } from 'src/utils/mask'
import { useTheme } from 'styled-components'

import { GroupedBenefit } from '../../utils/group-benefits'
import {
  BenefitItemEmojiStyled,
  BenefitItemWrapperStyled,
  BenefitsContainerStyled,
  BenefitsListWrapperStyled,
} from './styles'

type BenefitsProps = {
  benefits: GroupedBenefit[]
}

type BenefitItemProps = {
  benefit: GroupedBenefit
}

function BenefitItem({ benefit }: BenefitItemProps) {
  const theme = useTheme()
  const { name, emoji, amount } = benefit

  return (
    <BenefitItemWrapperStyled>
      <Tooltip title={name} arrow placement="left">
        <div>
          <BenefitItemEmojiStyled imgSrc={emoji} width={16} />

          <Typography variant="body4" variantColor={theme.colors.neutral30}>
            {name}
          </Typography>
        </div>
      </Tooltip>

      <Typography
        variant="body4"
        variantColor={theme.colors.neutral30}
        weight={700}
      >
        {formatCurrency(amount)}
      </Typography>
    </BenefitItemWrapperStyled>
  )
}

export function Benefits({ benefits }: BenefitsProps) {
  return (
    <BenefitsContainerStyled>
      <BenefitsListWrapperStyled>
        {benefits.map((benefit, key) => (
          <BenefitItem key={key} benefit={benefit} />
        ))}
      </BenefitsListWrapperStyled>
    </BenefitsContainerStyled>
  )
}
