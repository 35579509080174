import styled from 'styled-components'

import { SummaryItem } from './summary-item'

export interface ISummaryListProps {
  children: React.ReactElement<typeof SummaryItem>[]
  className?: string
}

const SummaryListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`

export const SummaryList: React.FC<ISummaryListProps> = ({
  children,
  className,
}: ISummaryListProps) => (
  <SummaryListContainer className={className}>{children}</SummaryListContainer>
)
