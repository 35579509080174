import { ConfirmationContent } from './components/content'
import { ConfirmationHeader } from './components/header'

interface IOrderConfirmationCard {
  Header: typeof ConfirmationHeader
  Content: typeof ConfirmationContent
}

export const OrderConfirmationCard: IOrderConfirmationCard = {
  Header: ConfirmationHeader,
  Content: ConfirmationContent,
}
