import { LinkButton, LinkButtonProps } from '@flash-tecnologia/hros-web-ui-v2'
import { Grid } from 'src/components/grid'

export interface IOrderLinkButtonProps
  extends Omit<LinkButtonProps, 'variant'> {}

export const OrderLinkButton: React.FC<IOrderLinkButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Grid.Item>
      <LinkButton variant="neutral" {...props}>
        {children}
      </LinkButton>
    </Grid.Item>
  )
}
