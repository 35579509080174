import { Button, Modal } from '@flash-tecnologia/hros-web-ui-v2'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { trpc } from 'src/api/client'
import { Table } from 'src/components/table'
import { dispatchToast } from 'src/utils/dispatchEvents'
import { useTheme } from 'styled-components'

import { mountColumns } from './components/table-employees-summary/columns'
import {
  ModalContainerStyled,
  ModalContentStyled,
  ModalFooterStyled,
} from './styles'

type ModalEmployeesOrderProps = {
  isOpen: boolean
  onClose: () => void
  orderId?: string
}

export function ModalEmployeesOrderSummary({
  isOpen,
  onClose,
  orderId,
}: ModalEmployeesOrderProps) {
  const company = useSelectedCompany()
  const { t } = useTranslation()
  const theme = useTheme()

  const [pagination, setPagination] = useState({ page: 1, limit: 10 })
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  const resetPagination = useCallback(() => {
    setPagination((prev) => ({ ...prev, page: 1 }))
  }, [])

  useDebounce(
    () => {
      resetPagination()
      setDebouncedSearchTerm(searchTerm)
    },
    500,
    [searchTerm],
  )

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event) {
        const value = event.target.value
        if (value !== searchTerm) {
          setSearchTerm(value)
        }
      } else {
        setSearchTerm('')
      }
    },
    [searchTerm],
  )
  const {
    data: orderDepositsSummary,
    isLoading: orderDepositsSummaryLoading,
    error: orderDepositsSummaryError,
  } = trpc.benefitOrders.getOrderDepositsSummary.useQuery(
    {
      orderId: orderId || '',
      page: pagination.page,
      limit: pagination.limit,
      query: debouncedSearchTerm,
    },
    {
      enabled: Boolean(orderId),
    },
  )

  useEffect(() => {
    if (orderDepositsSummaryError) {
      dispatchToast({
        content: t('orderDetails.failedToFetchMessage'),
        type: 'error',
        description: orderDepositsSummaryError.message,
      })
    }
  }, [orderDepositsSummaryError, t])

  return (
    <Modal.Root open={isOpen} onClose={onClose} size="md">
      <ModalContainerStyled>
        <Modal.Header
          title={t('modalEmployeesList.title')}
          description={t('modalEmployeesList.description')}
        />
        <ModalContentStyled>
          <Table
            columns={mountColumns(
              t,
              theme,
              company.selectedCompany,
              orderDepositsSummaryLoading,
              orderDepositsSummary,
            )}
            baseData={orderDepositsSummary?.data || []}
            title={t('modalEmployeesList.title')}
            tagColor={'gray'}
            loading={orderDepositsSummaryLoading}
            search={{
              value: debouncedSearchTerm,
              onChange: handleSearchChange,
              label: t('orderDetails.content.searchBar'),
            }}
            pagination={{
              onPaginationChange: (pagination: any) =>
                setPagination((prevValue) => ({
                  ...prevValue,
                  page: pagination.pageNumber,
                  limit: pagination.pageSize,
                })),
              totalItems: orderDepositsSummary?.meta?.count,
              totalPages: orderDepositsSummary?.meta?.totalPages ?? 0,
              pageNumber: pagination.page,
              pageSize: pagination.limit,
            }}
          />
        </ModalContentStyled>
        <ModalFooterStyled>
          <Button variant="primary" size="large" onClick={onClose}>
            OK
          </Button>
        </ModalFooterStyled>
      </ModalContainerStyled>
    </Modal.Root>
  )
}
