import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import styled from 'styled-components'

import { ConfirmationHeader } from '../../../../../components/order-confirmation-card/components/header'
import { OrderStatusEnum } from '../../../../../components/order-confirmation-card/components/header/components/tags/order-status-tag'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs};
`

export interface IOrderHeaderProps {
  status: OrderStatusEnum
  paymentMethod: PaymentMethodEnum
}

export const OrderHeader: React.FC<IOrderHeaderProps> = ({
  status,
  paymentMethod,
}) => {
  return (
    <HeaderContainer>
      <ShapeIcon
        size={48}
        variant="success"
        strokeWidth="2"
        name={'IconCheck'}
      />
      <TagsContainer>
        <ConfirmationHeader.Tags.PaymentMethodTag
          paymentMethod={paymentMethod}
        />
        <ConfirmationHeader.Tags.StatusTag status={status} />
      </TagsContainer>
    </HeaderContainer>
  )
}
