import { dayjs, Divider, Skeleton } from '@flash-tecnologia/hros-web-ui-v2'
import Grid from '@mui/material/Grid'
import { IBalances } from 'src/components/checkout/components/balance/balance-tag'

import { ICheckoutMethod } from '../../checkout-method.interface'
import { CheckoutDatesInputs } from '../dates-inputs'
import { FlashCashBalances, isBalanceInsufficient } from './flash-cash-balances'
import InsufficientBalanceCard from './insuficient-balance-card'

export interface IFlashCashCheckoutProps extends ICheckoutMethod {
  balances?: IBalances[]
  totalAmount?: number
  isTopup?: boolean
  cutoffValue?: dayjs.Dayjs
  loadingBalance?: boolean
}

export const FlashCashCheckout: React.FC<IFlashCashCheckoutProps> = ({
  disabled = false,
  balances = [],
  totalAmount,
  depositDate,
  onDepositDateChange,
  onCutoffDateChange,
  paymentMethod,
  isTopup = false,
  cutoffValue,
  loadingBalance = false,
}) => {
  return (
    <>
      {loadingBalance && (
        <Grid container flexDirection={'column'} gap={'8px'}>
          <Skeleton height={22} width={'240px'} variant="rounded" />
          <Skeleton height={34} width={'100%'} variant="rounded" />
        </Grid>
      )}

      {balances?.length > 0 && (
        <FlashCashBalances balances={balances} totalAmount={totalAmount ?? 0} />
      )}

      {!isTopup &&
        balances?.length > 0 &&
        totalAmount &&
        balances.some((balance) =>
          isBalanceInsufficient(balance, totalAmount),
        ) && <InsufficientBalanceCard />}

      <Divider orientation="horizontal" />

      <CheckoutDatesInputs
        disabled={disabled}
        value={depositDate}
        cutoffValue={cutoffValue}
        onDateChange={onDepositDateChange}
        onCutoffDateChange={onCutoffDateChange}
        paymentMethod={paymentMethod}
        isTopup={isTopup}
      />
    </>
  )
}
