import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import {
  legacyGetFromLS,
  useFlag,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'
import { GetBalancesResponse } from 'backend/src/routers/finance/procedures'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/api/client'
import { CreditTypeEnum } from 'src/components/orders/components/pre-order-checkout-modal/content/credit-type'
import { useOrder } from 'src/domain/checkout/context/order-context'
import { IPaymentMethod } from 'src/domain/checkout/presenters/payment-method/payment-method'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { FeatureFlagEnum } from 'src/enums/featureFlagsEnum'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { getPaymentMethods } from 'src/paymentMethodList'
import { dispatchToast } from 'src/utils/dispatchEvents'
import { useLegacySelectedCompany } from 'src/utils/hooks/useLegacySelectedCompany'

import { useNewOrderPage } from '../../../hooks/use-new-order-page'
import { useNewOrderStore } from '../../../store/use-new-order-store'
import { buildDeposits } from '../utils/build-deposits'
import { filterByCreditType } from '../utils/filter-by-credit-type'
import { isBalanceInsufficient } from '../utils/is-balance-insuficcient'

export function usePaymentSetup() {
  const { t } = useTranslation()

  const useCompany = useSelectedCompany()
  const { flashIssuerEnabled } = useLegacySelectedCompany()
  const { emailAssociated } = legacyGetFromLS('clientData')
  const pixPaymentEnabled = useFlag({
    flagName: FeatureFlagEnum.PIX_PAYMENT_METHOD,
  })

  const { setCreditConfigs } = useOrder()

  const { searchParams, setSearchParams } = useNewOrderPage()
  const {
    creditDays,
    isTopupCredit,
    order,
    setOrder,
    flashCashBalances,
    setFlashCashBalances,
    selectedEmployees,
    orderSummary,
    setOrderSummary,
    setBackButtonOptions,
    setContinueButtonOptions,
    nextStep,
    previousStep,
    resetOrder,
  } = useNewOrderStore()

  const [showEmployeesOrderModal, setShowEmployeesOrderModal] = useState(false)

  const creditType = searchParams.creditType as CreditTypeEnum

  const createOrder = trpc.benefitOrders.createOrder.useMutation({
    onSuccess: (data) => {
      setOrder({ id: data.id })
      setSearchParams((params) => {
        params.set('order', data.id)
        return params
      })
    },
    onError: (error) => {
      dispatchToast({
        content: `${t('order.errors.generic')}`,
        type: 'error',
        description: error.message,
      })
    },
  })

  const scheduleTopupMutation = useRef(
    trpc.topupManager.createScheduleTopup.useMutation({
      onError: (error) => {
        dispatchToast({
          content: `${t('order.errors.topupSchedule')}`,
          type: 'error',
          description: error.message,
        })
      },
    }),
  ).current

  const { data: balanceData, isLoading: balanceDataIsLoading } =
    trpc.financeServer.getBalance.useQuery({
      // Para este novo fluxo não temos separação entre benefícios plástico e virtual
      // Dado que brevemente todos benefícios serão hibridos, utilizaremos o plástico como padrão.
      balanceType: [BenefitTypeEnum.PLASTIC],
    })

  const { data: summaryData, error: summaryDataError } =
    trpc.benefitOrders.getSummary.useQuery(
      { id: order?.id ?? '' },
      { enabled: !!order?.id },
    )

  const { data: company } = trpc.benefitOrders.getCompany.useQuery({
    cnpjOrCompanyId: useCompany.selectedCompany.id,
  })

  const buildTopupDeposits = useMemo(
    () =>
      buildDeposits(
        filterByCreditType(selectedEmployees, CreditTypeEnum.TOPUP),
        creditDays,
      ),
    [creditDays, selectedEmployees],
  )

  useEffect(() => {
    if (!order) {
      createOrder.mutate({
        deposits: isTopupCredit
          ? buildTopupDeposits
          : buildDeposits(selectedEmployees, creditDays),
        creditType: creditType || CreditTypeEnum.ACCUMULATIVE,
        creditDays,
      })
    }
  }, [
    order,
    createOrder,
    creditType,
    selectedEmployees,
    creditDays,
    isTopupCredit,
    buildTopupDeposits,
  ])

  useEffect(() => {
    if (
      !order?.depositDate ||
      !order.depositDate.isValid() ||
      order.depositDate.isBefore(dayjs(), 'day')
    ) {
      setOrder({ dueDate: undefined })
      return
    }
    switch (order.paymentMethod) {
      case PaymentMethodEnum.BILLET:
        setOrder(
          // @ts-expect-error: Type error
          { dueDate: order.depositDate?.subtractBusinessDays(2) },
        )
        break

      case PaymentMethodEnum.PIX:
        setOrder({ dueDate: order.depositDate })
        break
      default:
        break
    }
  }, [order?.depositDate, order?.paymentMethod, setOrder])

  const isBalanceLowerThanTotal = useMemo(() => {
    if (!orderSummary?.totalAmount || !balanceData?.length) {
      return true
    }
    const total = orderSummary?.totalAmount + orderSummary?.totalFee

    return balanceData
      .map((balance) => ({ value: balance.amount, label: balance.type }))
      .some((balance) => isBalanceInsufficient(balance, total))
  }, [balanceData, orderSummary?.totalAmount, orderSummary?.totalFee])

  const validateForm = useCallback((): {
    isValid: boolean
    message?: string
  } => {
    if (!order?.paymentMethod) {
      return {
        isValid: false,
        message: `${t('order.errors.emptyPaymentMethod')}`,
      }
    }
    if (!order?.depositDate) {
      return {
        isValid: false,
        message: `${t('order.errors.emptyDepositDate')}`,
      }
    }

    switch (order.paymentMethod) {
      case PaymentMethodEnum.BILLET:
      case PaymentMethodEnum.PIX:
        break
      case PaymentMethodEnum.FLASH_CASH:
        if (isBalanceLowerThanTotal) {
          return {
            isValid: false,
            message: `${t('order.errors.balance')}`,
          }
        }
        break
      default:
        return {
          isValid: false,
          message: `${t('order.errors.emptyPaymentMethod')}`,
        }
    }
    return {
      isValid: true,
    }
  }, [order?.paymentMethod, order?.depositDate, isBalanceLowerThanTotal, t])

  const handleContinue = useCallback(async () => {
    const { isValid, message } = validateForm()
    if (!isValid && !isTopupCredit) {
      dispatchToast({
        content: message as string,
        type: 'warning',
      })
      return
    }

    if (isTopupCredit) {
      const deposits = buildTopupDeposits

      const topupSchedule = await scheduleTopupMutation.mutateAsync({
        orderId: order?.id as string,
        adminEmail: emailAssociated,
        cnpj: useCompany.selectedCompany.registrationNumber,
        creditDate: order?.depositDate?.toDate() as Date,
        cutoffDate: order?.cutoffDate?.toDate() as Date,
        topups: deposits.map((deposit) => ({
          benefitId: deposit.benefitId,
          employeeId: deposit.employeeId,
          total: deposit.amount / 100,
        })),
      })

      setSearchParams((params) => {
        params.set('schedule', topupSchedule.id)
        return params
      })
    }

    nextStep()
  }, [
    validateForm,
    isTopupCredit,
    nextStep,
    buildTopupDeposits,
    scheduleTopupMutation,
    order?.id,
    order?.depositDate,
    order?.cutoffDate,
    emailAssociated,
    useCompany.selectedCompany.registrationNumber,
    setSearchParams,
  ])

  const handleBack = useCallback(() => {
    resetOrder()
    setOrderSummary(null)
    previousStep()
  }, [previousStep, resetOrder, setOrderSummary])

  const availablePaymentMethods: IPaymentMethod[] | undefined = useMemo(() => {
    if (isTopupCredit) {
      setOrder({ paymentMethod: PaymentMethodEnum.FLASH_CASH })
    }

    return getPaymentMethods({
      billet: { show: !isTopupCredit },
      flashCash: {
        show: true,
        disabled: isTopupCredit ?? !balanceData?.length,
      },
      pix: {
        show: !isTopupCredit && pixPaymentEnabled && flashIssuerEnabled,
      },
    })
  }, [
    balanceData?.length,
    isTopupCredit,
    flashIssuerEnabled,
    pixPaymentEnabled,
    setOrder,
  ])

  const toggleEmployeesOrderModal = useCallback(() => {
    setShowEmployeesOrderModal(!showEmployeesOrderModal)
  }, [showEmployeesOrderModal, setShowEmployeesOrderModal])

  const handlePaymentMethodChange = useCallback(
    (paymentMethod: PaymentMethodEnum) => {
      setOrder({
        paymentMethod,
        depositDate: undefined,
        dueDate: undefined,
      })
    },
    [setOrder],
  )

  const handleDepositDateChange = useCallback(
    (depositDate: dayjs.Dayjs | undefined) => {
      setOrder({ depositDate })
    },
    [setOrder],
  )

  const handleCutoffDateChange = useCallback(
    (cutoffDate: dayjs.Dayjs | undefined) => {
      setOrder({ cutoffDate })
    },
    [setOrder],
  )

  const handleSetReceiptDescription = useCallback(
    (receiptDescription: string) => {
      setOrder({ receiptDescription })
    },
    [setOrder],
  )

  useEffect(() => {
    if (balanceData && balanceData.length > 0) {
      const flashCashBalances = balanceData.map(
        (balance: GetBalancesResponse) => ({
          label: balance.type,
          value: balance.amount,
        }),
      )
      setFlashCashBalances(flashCashBalances)
    }
  }, [balanceData, setFlashCashBalances])

  // @ToDo: será necessário refatorar o componente <CheckoutMethod /> para que não seja necessário
  // o store `useOrders`.
  useEffect(() => {
    if (company?.creditConfigs) {
      setCreditConfigs(company.creditConfigs)
    }
  }, [company, setCreditConfigs])

  useEffect(() => {
    if (summaryDataError?.message === 'FORBIDDEN') {
      return dispatchToast({
        content: `${t('order.errors.generic')}`,
        type: 'error',
        description: summaryDataError.message,
      })
    }

    if (summaryData) {
      setOrderSummary(summaryData)
    }
  }, [summaryData, setOrderSummary, summaryDataError, t])

  useEffect(() => {
    setContinueButtonOptions({
      text: t('newOrder.buttons.next'),
      onClick: handleContinue,
    })
    setBackButtonOptions({
      text: t('newOrder.buttons.back'),
      onClick: handleBack,
      disabled: createOrder.isLoading,
      iconName: 'IconArrowLeft',
      iconPosition: 'left',
    })
  }, [
    setContinueButtonOptions,
    setBackButtonOptions,
    t,
    handleContinue,
    handleBack,
    createOrder.isLoading,
  ])

  return {
    creditDays,
    availablePaymentMethods,
    balanceDataIsLoading,
    flashCashBalances,
    handleCutoffDateChange,
    handleDepositDateChange,
    handlePaymentMethodChange,
    handleSetReceiptDescription,
    isTopupCredit,
    order,
    orderSummary,
    showEmployeesOrderModal,
    toggleEmployeesOrderModal,
  }
}
