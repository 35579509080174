import { ListTitle, TitleRow } from './item-title'
import { SummaryItem } from './summary-item'
import { SummaryList } from './summary-list'
import { SummaryContainer } from './summary-root'

export const OrderSummary = {
  Root: SummaryContainer,
  List: SummaryList,
  Item: SummaryItem,
  ItemRoot: TitleRow,
  ListTitle,
}
