import { CSSProperties } from 'react'
import { formatCurrency } from 'src/utils/mask'

import { useEmployeeSelection } from '../../hooks/use-employee-selection'
import { Employee } from '../../types'
import { ActionCell } from '../ActionCell'
import { EmojisCell } from '../EmojisCell'
import { GroupNameCell } from '../group-name-cell'
import { SelectionCheckboxCell } from '../SelectionCheckboxCell'
import { Container } from './styles'

type RowEmployeeProps = {
  employee: Employee
  style: CSSProperties
}

export function RowEmployee({ employee, style }: RowEmployeeProps) {
  const { tableColumnWidths, selectedEmployeeIds, toggleEmployeeSelection } =
    useEmployeeSelection()

  const [checkboxWidth, groupWidth, benefitWidth, amountWidth, actionsGroup] =
    tableColumnWidths

  return (
    <Container style={style}>
      <div style={{ width: checkboxWidth }}>
        <SelectionCheckboxCell
          groupOrEmployee={employee}
          selectedEmployeeIds={selectedEmployeeIds}
          toggleEmployeeSelection={toggleEmployeeSelection}
        />
      </div>

      <div style={{ width: groupWidth }}>
        <GroupNameCell groupOrEmployee={employee} />
      </div>

      <div style={{ width: benefitWidth }}>
        <EmojisCell groupOrEmployee={employee} isEmployee />
      </div>

      <div style={{ width: amountWidth }}>{formatCurrency(employee.total)}</div>

      <div style={{ width: actionsGroup }}>
        <ActionCell groupOrEmployee={employee} isEmployee />
      </div>
    </Container>
  )
}
