import { Checkbox, tableControllers } from '@flash-tecnologia/hros-web-ui-v2'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { usePagination } from 'src/utils/hooks'
import { formatCurrency } from 'src/utils/mask'

import { ActionCell } from '../components/ActionCell'
import { EmojisCell } from '../components/EmojisCell'
import { GroupNameCell } from '../components/group-name-cell'
import { SelectionCheckboxCell } from '../components/SelectionCheckboxCell'
import { Group } from '../types'

type UseTableConfigProps = {
  groups: Group[]
  areAllSelected: boolean
  isHeaderIndeterminate: boolean
  toggleSelectAll: () => void
  selectedEmployeeIds: string[]
  isRowSelected: (id: string) => boolean
  isRowIndeterminate: (id: string) => boolean
  toggleRowSelection: (id: string) => void
}

export function useTableConfig({
  groups,
  areAllSelected,
  isHeaderIndeterminate,
  toggleSelectAll,
  selectedEmployeeIds,
  isRowSelected,
  isRowIndeterminate,
  toggleRowSelection,
}: UseTableConfigProps) {
  const { t } = useTranslation()
  const { pagination, setPagination, getPaginatedData } = usePagination()

  const data = getPaginatedData(groups)

  const columnHelper = createColumnHelper<Group>()

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'checkbox',
        header: () => (
          <Checkbox
            checked={areAllSelected}
            indeterminate={isHeaderIndeterminate}
            onChange={toggleSelectAll}
          />
        ),
        cell: ({ row }) => (
          <SelectionCheckboxCell
            groupOrEmployee={row.original}
            selectedEmployeeIds={selectedEmployeeIds}
            isRowSelected={isRowSelected}
            isRowIndeterminate={isRowIndeterminate}
            toggleRowSelection={toggleRowSelection}
          />
        ),
      }),
      columnHelper.accessor('name', {
        header: t('newOrder.employeeSelection.table.columns.group'),
        cell: ({ row }) => <GroupNameCell groupOrEmployee={row.original} />,
      }),
      columnHelper.accessor('benefits', {
        header: t('newOrder.employeeSelection.table.columns.benefits'),
        cell: ({ row }) => <EmojisCell groupOrEmployee={row.original} />,
      }),
      columnHelper.accessor('total', {
        header: t('newOrder.employeeSelection.table.columns.totalAmount'),
        cell: ({ row }) => formatCurrency(row.getValue('total')),
      }),
      columnHelper.display({
        id: 'action',
        header: t('newOrder.employeeSelection.table.columns.actions'),
        cell: ({ row }) => (
          <ActionCell
            expandable={{
              handleToogle: row.toggleExpanded,
              isOpen: row.getIsExpanded(),
            }}
          />
        ),
      }),
    ],
    [
      areAllSelected,
      columnHelper,
      isHeaderIndeterminate,
      isRowIndeterminate,
      isRowSelected,
      selectedEmployeeIds,
      t,
      toggleRowSelection,
      toggleSelectAll,
    ],
  )

  const { getHeaderGroups, toggleAllRowsExpanded, rows } =
    tableControllers.useTableColumns({
      columns: columns as never,
      pagination,
      data,
      onPaginationChange: setPagination,
    })

  return {
    getHeaderGroups,
    toggleAllRowsExpanded,
    rows,
    pagination,
    setPagination,
  }
}
