import { dayjs, Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { OrderSummary } from '../summary'

export interface IOrderSymmaryDepositDateProps {
  depositDate: dayjs.Dayjs
}

export const OrderSummaryDepositDate: React.FC<
  IOrderSymmaryDepositDateProps
> = ({ depositDate }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <OrderSummary.Root>
      <OrderSummary.ListTitle>
        <Typography variant="headline8" variantColor={theme.colors.neutral20}>
          {t('order.review.cardGrid.orderSummary.depositDate.title')}
        </Typography>
      </OrderSummary.ListTitle>
      <OrderSummary.ItemRoot>
        <Icons name="IconCalendar" size={24} />
        <Typography variant="body4" variantColor={theme.colors.neutral30}>
          {dayjs(depositDate).format('DD/MM/YYYY')}
        </Typography>
      </OrderSummary.ItemRoot>
    </OrderSummary.Root>
  )
}
