import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const BenefitEditWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.xs2};

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 7px; // 16px (space) - 9px (checkbox padding)

    > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: ${({ theme }) => theme.spacings.xs4};
    }
  }
`

export const TypographyStyled = styled(Typography)`
  max-width: 300px;
`
