import { Description } from './components/description'
import { Title } from './components/title'
import { ModalAlertConfirmRoot } from './modal-alert-confirm-root'

interface IModalAlertConfirm {
  Root: typeof ModalAlertConfirmRoot
  Title: typeof Title
  Description: typeof Description
}

export const ModalAlertConfirm: IModalAlertConfirm = {
  Root: ModalAlertConfirmRoot,
  Title,
  Description,
}
