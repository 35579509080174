import { Button, LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { trpc } from 'src/api/client'
import { dispatchToast } from 'src/utils/dispatchEvents'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import styled from 'styled-components'

import { BilletsLinkButton } from './billet-confirmation/components/billets-link-button'

const OrderLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs};
`

export interface IOrderLinksProps {
  extraLink?: React.ReactElement<typeof BilletsLinkButton>
  openNewTab: (_string: string) => void
}

export const OrderLinks: React.FC<IOrderLinksProps> = ({
  extraLink: aditionalLinks,
  openNewTab,
}) => {
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const { trackEvent } = useEventTracking()

  const queryOrderId = searchParams.get('orderId')
  const orderId = queryOrderId || params.orderId

  const { mutate } = trpc.legacyMigration.generateOrderReport.useMutation({
    onSuccess: (data) => {
      window.open(data.tempUrl, '_blank')?.focus()
    },
    onError: (error) => {
      dispatchToast({
        content: `${t('order.errors.reportError')}`,
        type: 'error',
        description: error.message,
      })
    },
    onSettled: () => {
      setIsReportLoading(false)
    },
  })

  const onClickReport = () => {
    if (!isReportLoading) {
      setIsReportLoading(true)
      trackEvent({
        name: 'FlashOS_BenefitOrders_Checkout_Confirmation_Report_Click',
      })
      if (orderId) mutate({ orderId })
    }
  }

  const onClickDeposits = () => {
    trackEvent({
      name: 'FlashOS_BenefitOrders_Checkout_Confirmation_Deposits_Click',
    })
    openNewTab('benefits/dashboard/company/deposits')
  }

  return (
    <OrderLinksContainer>
      <LinkButtonsContainer>
        <LinkButton variant="neutral" onClick={onClickDeposits}>
          {t('order.confirmation.cardGrid.buttons.links.deposits')}
        </LinkButton>
        {aditionalLinks}
      </LinkButtonsContainer>
      <Button
        size={'small'}
        variant="secondary"
        onClick={onClickReport}
        loading={isReportLoading}
      >
        {t('order.confirmation.cardGrid.buttons.generateReport')}
      </Button>
    </OrderLinksContainer>
  )
}
