import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { cpfMask } from 'src/utils/mask'
import { useTheme } from 'styled-components'

import { Employee, Group } from '../../types'
import { GroupNameWrapperStyled } from './styles'

type GroupNameCellProps = {
  groupOrEmployee: Group | Employee
}

export function GroupNameCell({ groupOrEmployee }: GroupNameCellProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const isEmployee = !!(groupOrEmployee as Employee)?.document

  return (
    <GroupNameWrapperStyled>
      <Typography
        variant="body3"
        weight={600}
        variantColor={theme.colors.neutral40}
      >
        {groupOrEmployee.name}
      </Typography>

      <div>
        {isEmployee ? (
          <>
            <Icons name="IconId" size={16} />

            <Typography variant="body4">
              {cpfMask((groupOrEmployee as Employee).document)}
            </Typography>
          </>
        ) : (
          <>
            <Icons name="IconUsers" size={16} />

            <Typography variant="body4">
              {(groupOrEmployee as Group).employees.length}{' '}
              {(groupOrEmployee as Group).employees.length > 1
                ? t('newOrder.employeeSelection.table.groupNameCell.people')
                : t('newOrder.employeeSelection.table.groupNameCell.person')}
            </Typography>
          </>
        )}
      </div>
    </GroupNameWrapperStyled>
  )
}
