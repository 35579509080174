import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

type PaymentMethodIconsNames = 'BarcodeMethod' | 'IconWallet' | 'Pix'

interface IPaymentMethodIconsMap {
  [PaymentMethodEnum.BILLET]: 'BarcodeMethod'
  [PaymentMethodEnum.FLASH_CASH]: 'IconWallet'
  [PaymentMethodEnum.PIX]: 'Pix'
}

const paymentMethodIconsMap: IPaymentMethodIconsMap = {
  [PaymentMethodEnum.BILLET]: 'BarcodeMethod',
  [PaymentMethodEnum.FLASH_CASH]: 'IconWallet',
  [PaymentMethodEnum.PIX]: 'Pix',
}

interface IPaymentMethodIconProps {
  paymentMethod: PaymentMethodEnum
  disabled?: boolean
  size?: number
}

export const PaymentMethodIcon: React.FC<IPaymentMethodIconProps> = ({
  paymentMethod,
  disabled,
  size = 40,
}) => {
  const colorVariant = disabled ? 'disabled' : 'default'
  const icon: PaymentMethodIconsNames = paymentMethodIconsMap[paymentMethod]
  return (
    <ShapeIcon
      size={size}
      color={colorVariant}
      stroke={colorVariant}
      variant={colorVariant}
      strokeWidth="2"
      name={icon}
    />
  )
}
