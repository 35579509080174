import Grid from '@mui/material/Grid'
import React from 'react'
import { useTheme } from 'styled-components'

interface ISideGridProps extends React.ComponentProps<typeof Grid> {}

export const SideGrid: React.FC<ISideGridProps> = ({ children, ...props }) => {
  const theme = useTheme()

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={theme.spacings.xs}
      {...props}
    >
      {children}
    </Grid>
  )
}
