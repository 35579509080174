import { Button, Icons, Modal } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { ReactNode } from 'react'

import {
  ButtonCancelStyled,
  ContainerStyled,
  IconWrapperStyled,
} from './components/styles'

interface IModalAlertConfirmRoot {
  children: ReactNode
  isOpen: boolean
  textCancel?: string
  textConfirm?: string
  onClose: () => void
  onConfirm: () => void
}

export const ModalAlertConfirmRoot = ({
  children,
  isOpen,
  textCancel,
  textConfirm,
  onClose,
  onConfirm,
}: IModalAlertConfirmRoot) => {
  return (
    <Modal.Root open={isOpen} onClose={onClose}>
      <>
        <ContainerStyled>
          <IconWrapperStyled>
            <Icons name="IconAlertCircle" size={64} />
          </IconWrapperStyled>

          {children}
        </ContainerStyled>

        <Modal.Footer>
          <ButtonCancelStyled variant="neutral" onClick={onClose}>
            {textCancel ?? t('modalAlertConfirm.btnCancel')}
          </ButtonCancelStyled>

          <Button variant="primary" size="medium" onClick={onConfirm}>
            {textConfirm ?? t('modalAlertConfirm.btnConfirm')}
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
