import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

export interface IBalanceRootProps {
  children: React.ComponentProps<typeof Grid.Item>['children']
}

export const BalanceRoot: React.FC<IBalanceRootProps> = ({ children }) => {
  const theme = useTheme()
  return (
    <Grid.Item container direction={'column'} gap={theme.spacings.xs3}>
      {children}
    </Grid.Item>
  )
}
