import { Modal } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ModalFooterStyled = styled(Modal.Footer)`
  align-items: center;
  .content-footer {
    > button {
      min-width: 198px;
    }
  }
`

export const ModalContentStyled = styled(Modal.Content)`
  height: 350px;
  overflow-y: auto;
  table {
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`

export const ModalContainerStyled = styled.div`
  max-height: 650px;
`
