import styled from 'styled-components'

export const ContainerStyled = styled.div<{ $width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ $width }) => $width}px;
  height: ${({ $width }) => $width}px;
  background-color: ${({ theme }) => theme.colors.neutral90};
  padding: calc(${({ $width }) => $width}px * 0.16625);
  border-radius: ${({ theme }) => theme.borders.radius.pill};

  > img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`
