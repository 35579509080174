import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { OrderConfirmationCard } from 'src/components/order-confirmation-card'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

interface IOrderReminderProps {
  depositDate: dayjs.Dayjs
  paymentMethod: PaymentMethodEnum
}

export const OrderReminder: React.FC<IOrderReminderProps> = ({
  depositDate,
  paymentMethod,
}) => {
  const depositDateString = dayjs(depositDate).format('DD/MM')
  const prefix = t(`order.confirmation.cardGrid.reminder.subtitle.prefix`)
  const sufix = t(
    `order.confirmation.cardGrid.reminder.subtitle.sufix.${paymentMethod}`,
  )
  return (
    <OrderConfirmationCard.Content.Reminder
      title={t(`order.confirmation.cardGrid.reminder.title`)}
    >
      {prefix} <b>{depositDateString}</b> {sufix}
    </OrderConfirmationCard.Content.Reminder>
  )
}
