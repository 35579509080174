import { Divider, NotificationCard } from '@flash-tecnologia/hros-web-ui-v2'
import { Hidden } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { CheckoutMethod } from 'src/domain/checkout/presenters/checkout-method/checkout-method'
import { CheckoutMethodSkeleton } from 'src/domain/checkout/presenters/checkout-method/components/checkout-method-skeleton'
import { ReceiptDescription } from 'src/domain/checkout/presenters/checkout-method/components/receipt-description'
import { OrderSummarySkeleton } from 'src/domain/checkout/presenters/order-summary/components/order-summary-skeleton'
import { OrderSummary } from 'src/domain/checkout/presenters/order-summary/order-summary'
import PaymentMethodHeader from 'src/domain/checkout/presenters/payment-method/payment-method-header'
import PaymentMethods from 'src/domain/checkout/presenters/payment-method/payment-methods'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

import { ModalEmployeesOrderSummary } from '../../components/modal-employees-order-summary'
import { usePaymentSetup } from './hooks/use-payment-setup'

export function PaymentSetup() {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    availablePaymentMethods,
    balanceDataIsLoading,
    flashCashBalances,
    handleCutoffDateChange,
    handleDepositDateChange,
    handlePaymentMethodChange,
    handleSetReceiptDescription,
    isTopupCredit,
    order,
    orderSummary,
    showEmployeesOrderModal,
    toggleEmployeesOrderModal,
    creditDays,
  } = usePaymentSetup()

  return (
    <>
      <ModalEmployeesOrderSummary
        isOpen={showEmployeesOrderModal}
        onClose={toggleEmployeesOrderModal}
        orderId={order?.id}
      />

      <Grid.Container container rowGap={{ xs: theme.spacings.s }}>
        <Grid.CardGrid item xs={12} xl={8}>
          <PaymentMethodHeader isTopup={isTopupCredit ?? false} />
          {availablePaymentMethods && order?.id ? (
            <PaymentMethods
              paymentMethods={availablePaymentMethods}
              value={order.paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              {isTopupCredit && (
                <NotificationCard.Root type="info" variant="outlined">
                  <NotificationCard.Icon iconName="IconAlertCircle" />

                  <NotificationCard.WrapperTexts>
                    <NotificationCard.Title>
                      {t('order.paymentMethods.topupWarning.title')}
                    </NotificationCard.Title>
                    <NotificationCard.Subtitle>
                      {t('order.paymentMethods.topupWarning.subtitle')}
                    </NotificationCard.Subtitle>
                  </NotificationCard.WrapperTexts>
                </NotificationCard.Root>
              )}
            </PaymentMethods>
          ) : (
            <CheckoutMethodSkeleton /> // @TODO: fix skeleton width
          )}

          <CheckoutMethod
            disabled={Boolean(!order?.paymentMethod)}
            paymentMethod={order?.paymentMethod ?? PaymentMethodEnum.BILLET}
            balances={flashCashBalances}
            loadingBalance={balanceDataIsLoading}
            depositDate={order?.depositDate}
            cutoffValue={order?.cutoffDate}
            onDepositDateChange={handleDepositDateChange}
            onCutoffDateChange={handleCutoffDateChange}
            dueDate={order?.dueDate}
            totalAmount={orderSummary?.totalAmount}
            isTopup={isTopupCredit}
          />
          <ReceiptDescription
            value={order?.receiptDescription}
            disabled={Boolean(!order?.paymentMethod)}
            onChange={(e) =>
              handleSetReceiptDescription(
                (e.target as HTMLTextAreaElement).value,
              )
            }
          />
        </Grid.CardGrid>
        <Hidden xlUp>
          <Divider orientation="horizontal" />
        </Hidden>
        <Grid.SideGrid item xs={12} xl={4} pl={{ xl: theme.spacings.s }}>
          {order?.id && orderSummary ? (
            <Grid.CardGrid item p={0} gap={0}>
              <OrderSummary
                isTopup={isTopupCredit ?? false}
                employeesCount={orderSummary.employeeCount || 0}
                showEmployeesOnClick={toggleEmployeesOrderModal}
                benefits={orderSummary?.benefits?.map((benefit) => ({
                  image: benefit.emoji,
                  label: benefit.name,
                  value: benefit.amount,
                }))}
                totals={
                  orderSummary
                    ? [
                        {
                          // Para este novo fluxo não temos separação entre benefícios plástico e virtual
                          // Dado que brevemente todos os benefícios serão hibridos, utilizaremos o plástico como padrão.
                          key: BenefitTypeEnum.PLASTIC,
                          value: orderSummary?.totalAmount,
                        },
                      ]
                    : undefined
                }
                creditDays={creditDays}
              />
            </Grid.CardGrid>
          ) : (
            <OrderSummarySkeleton />
          )}
        </Grid.SideGrid>
      </Grid.Container>
    </>
  )
}
