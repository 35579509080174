import { FixedSizeList as List } from 'react-window'

import { Employee } from '../../types'
import { RowEmployee } from '../row-employee'
import { useVirtualList } from './use-virtual-list'

type VirtualListProps = {
  employees: Employee[]
}

export function VirtualList({ employees }: VirtualListProps) {
  const { height, totalEmployees, elementHeight, overscanCount } =
    useVirtualList({ employees })

  return (
    <List
      width="100%"
      height={height}
      itemCount={totalEmployees}
      itemSize={elementHeight}
      itemData={employees}
      overscanCount={overscanCount}
    >
      {({ index, style, data }) => (
        <RowEmployee
          key={data[index].id}
          style={style}
          employee={data[index]}
        />
      )}
    </List>
  )
}
