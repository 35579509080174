import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DepositTimeUnitEnum } from 'src/domain/orders/pages/new-order-page/steps/employee-selection/types'
import { useNewOrderStore } from 'src/domain/orders/pages/new-order-page/store/use-new-order-store'
import { useToast } from 'src/utils/hooks/useToast'

import { GroupedBenefit } from '../../../utils/group-benefits'

const DAYS_INPUT_MIN = '1'
const DAYS_INPUT_MAX = '31'

type UseEditDays = {
  benefits: GroupedBenefit[]
}

export function useEditDays({ benefits }: UseEditDays) {
  const { t } = useTranslation()
  const { toastWarning } = useToast()
  const { creditDays, setCreditDays } = useNewOrderStore()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [daysInput, setDaysInput] = useState('')

  const dailyBenefits = useMemo(
    () =>
      benefits.filter(
        (benefit) => benefit.depositTimeUnit === DepositTimeUnitEnum.DAY,
      ),
    [benefits],
  )

  const hasDailyBenefits = useMemo(
    () => dailyBenefits.length > 0,
    [dailyBenefits.length],
  )

  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev)
  }, [])

  const handleDaysInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value

      if (!/^\d*$/.test(inputValue) || inputValue.length > 2) {
        return
      }

      const numericValue = Number(inputValue)

      if (numericValue > Number(DAYS_INPUT_MAX)) {
        setDaysInput(DAYS_INPUT_MAX)
      } else if (numericValue < Number(DAYS_INPUT_MIN) && inputValue !== '') {
        setDaysInput(DAYS_INPUT_MIN)
      } else {
        setDaysInput(inputValue)
      }
    },
    [],
  )

  const saveDays = useCallback(() => {
    if (!daysInput) {
      toastWarning({
        title: t('newOrder.orderSummary.creditDays.toastWarning'),
      })

      return
    }

    setCreditDays(Number(daysInput))
    setIsModalOpen(false)
  }, [daysInput, setCreditDays, t, toastWarning])

  useEffect(() => {
    if (creditDays) {
      setDaysInput(String(creditDays))
    }
  }, [creditDays])

  return {
    isModalOpen,
    dailyBenefits,
    handleDaysInputChange,
    creditDays,
    saveDays,
    daysInput,
    toggleModal,
    hasDailyBenefits,
  }
}
