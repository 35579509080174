import { Grid } from 'src/components/grid'
import { RadioCard } from 'src/components/radio-card'
import { RadioCardTextTypeEnum } from 'src/components/radio-card/radio-card-text/radio-card-text'
import { useTheme } from 'styled-components'

type CreditTypeRadioCardProps = {
  description: string
  disabled?: boolean
  onSelect: () => void
  selected: boolean
  title: string
}

export const CreditTypeRadioCard = ({
  description,
  disabled = false,
  onSelect,
  selected,
  title,
}: CreditTypeRadioCardProps) => {
  const theme = useTheme()

  return (
    <RadioCard.Root checked={selected} onClick={onSelect} disabled={disabled}>
      <Grid.Container
        alignSelf="start"
        flex={1}
        flexDirection="column"
        gap={theme.spacings.xs2}
        paddingX={theme.spacings.xs4}
        paddingY={theme.spacings.xs3}
        textAlign="left"
      >
        <Grid.Container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height={theme.spacings.s}
        >
          <RadioCard.Text
            checked={selected}
            disabled={disabled}
            text={title}
            textVariant="body3"
            type={RadioCardTextTypeEnum.TITLE}
          />

          <RadioCard.Radio checked={selected} disabled={disabled} />
        </Grid.Container>

        <RadioCard.Text
          checked={selected}
          disabled={disabled}
          text={description}
          textVariant="body4"
        />
      </Grid.Container>
    </RadioCard.Root>
  )
}
