import { t } from 'i18next'
import { useCallback } from 'react'
import { trpc } from 'src/api/client'
import { useNewOrderStore } from 'src/domain/orders/pages/new-order-page/store/use-new-order-store'
import { dispatchToast } from 'src/utils/dispatchEvents'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import { openNewTab } from 'src/utils/openNewTab'

export function useOrderLinks() {
  const { trackEvent } = useEventTracking()
  const { order } = useNewOrderStore()

  const handleOpenNewTab = useCallback((_path: string) => {
    const url = `${window.location.origin}/${_path}`

    openNewTab(url)
  }, [])

  const { mutate: generateReportMutate, isLoading: isLoadingReport } =
    trpc.legacyMigration.generateOrderReport.useMutation({
      onSuccess: (data) => {
        openNewTab(data.tempUrl)
      },
      onError: (error) => {
        dispatchToast({
          content: `${t('order.errors.reportError')}`,
          type: 'error',
          description: error.message,
        })
      },
    })

  const generateReport = useCallback(() => {
    if (!isLoadingReport) {
      trackEvent({
        name: 'FlashOS_BenefitOrders_Checkout_Confirmation_Report_Click',
      })
      if (order?.id) generateReportMutate({ orderId: order.id })
    }
  }, [generateReportMutate, isLoadingReport, order?.id, trackEvent])

  const onClickOrderDetails = useCallback(() => {
    trackEvent({
      name: 'FlashOS_BenefitOrders_Checkout_Confirmation_Deposits_Click',
    })
    handleOpenNewTab(`benefit-order/orders/${order?.id}`)
  }, [trackEvent, handleOpenNewTab, order?.id])

  return {
    isLoadingReport,
    generateReport,
    onClickOrderDetails,
  }
}
