import { OrderStatusTag } from './order-status-tag'
import { PaymentMethodTag } from './payment-method-tag'

interface IConfirmationHeaderTags {
  StatusTag: typeof OrderStatusTag
  PaymentMethodTag: typeof PaymentMethodTag
}

export const ConfirmationHeaderTags: IConfirmationHeaderTags = {
  StatusTag: OrderStatusTag,
  PaymentMethodTag,
}
