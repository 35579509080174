import styled from 'styled-components'

export const TotalPartialContainerStyled = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary99};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs4};
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xs2};
  width: 100%;
`
export const TotalPartialTextContainerStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
`
