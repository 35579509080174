import { LinkButton, NotificationCard } from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Employee } from '../../../../steps/employee-selection/types'
import { EditAssignmentsModal } from '../../../edit-assignments-modal'
import { EditAssignmentModeEnum } from '../../../edit-assignments-modal/context'
import { WrapperActionsStyled } from './styles'

type UnassignEmployeesCardProps = {
  onClick: () => void
  employeesWithoutAssign: Employee[]
}

export function UnassignEmployeesCard({
  employeesWithoutAssign,
  onClick,
}: UnassignEmployeesCardProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const totalEmployees = useMemo(
    () => employeesWithoutAssign.length,
    [employeesWithoutAssign.length],
  )

  const getTitle = useMemo(() => {
    if (totalEmployees > 1) {
      return 'Pessoas sem atribuições'
    }

    return 'Pessoa sem atribuição'
  }, [totalEmployees])

  const getSubtitle = useMemo(() => {
    if (totalEmployees > 1) {
      return `Você selecionou ${totalEmployees} pessoas sem benefícios atribuídos.`
    }

    return 'Você selecionou 1 pessoa sem benefícios atribuídos.'
  }, [totalEmployees])

  return (
    <>
      <NotificationCard.Root type="error" variant="contained" isVertical>
        <NotificationCard.WrapperIconWithText>
          <NotificationCard.Icon size={16} iconName="IconAlertTriangle" />

          <NotificationCard.Title>{getTitle}</NotificationCard.Title>
        </NotificationCard.WrapperIconWithText>

        <NotificationCard.Subtitle>{getSubtitle}</NotificationCard.Subtitle>

        <WrapperActionsStyled>
          <LinkButton variant="error" onClick={onClick}>
            {t('newOrder.orderSummary.noEmployeeAssign.btnRemove')}
          </LinkButton>

          <LinkButton variant="error" onClick={() => setIsOpen(true)}>
            Editar benefícios
          </LinkButton>
        </WrapperActionsStyled>
      </NotificationCard.Root>

      {isOpen && (
        <EditAssignmentsModal
          mode={EditAssignmentModeEnum.UNASSIGNED_EMPLOYEES}
          employees={employeesWithoutAssign}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}
