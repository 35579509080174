import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral95};

  border-bottom: ${({ theme }) =>
    `${theme.borders.width.xs2} solid  ${theme.colors.neutral80}`};

  > div {
    padding: ${({ theme }) => theme.spacings.xs};
  }
`
