import React from 'react'
import { IRootProps, RootDiv } from 'src/components/root'
import styled from 'styled-components'

export interface ICheckoutPropsRoot extends IRootProps {}

const StyledRootDiv = styled(RootDiv)``

export const CheckoutRoot: React.FC<ICheckoutPropsRoot> = ({
  children,
  ...rest
}) => {
  return <StyledRootDiv {...rest}>{children}</StyledRootDiv>
}
