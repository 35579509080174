import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2'

import { IPaymentMethod } from './domain/checkout/presenters/payment-method/payment-method'
import { PaymentMethodEnum } from './enums/paymentMethodEnum'

interface IGetPaymentMethod {
  show?: boolean
  disabled?: boolean
}

interface IGetPaymentMethodsProps {
  billet?: IGetPaymentMethod
  flashCash?: IGetPaymentMethod
  pix?: IGetPaymentMethod
}

export const getPaymentMethods = ({
  billet = { show: true, disabled: false },
  flashCash = { show: false, disabled: false },
  pix = { show: false, disabled: false },
}: IGetPaymentMethodsProps): IPaymentMethod[] => {
  const paymentMethods: IPaymentMethod[] = []

  if (billet?.show) {
    paymentMethods.push({
      disabled: billet.disabled ?? false,
      title: 'Boleto bancário',
      description:
        'Pode ser pago através de uma conta ou agência bancária e tem prazo de compensação de até 3 dias úteis.',
      icon: 'BarcodeMethod',
      methodKey: PaymentMethodEnum.BILLET,
    })
  }

  if (flashCash?.show) {
    paymentMethods.push({
      disabled: flashCash.disabled ?? false,
      title: 'Flash Cash de benefícios',
      description:
        'Faça pedidos em tempo real, tenha dinheiro na conta de Flash Cash para fazer depósitos a qualquer hora.',
      icon: 'IconWallet' as IconsProps['name'],
      methodKey: PaymentMethodEnum.FLASH_CASH,
    })
  }

  if (pix?.show) {
    paymentMethods.push({
      disabled: pix.disabled ?? false,
      title: 'Pix',
      description:
        'Com o Pix você tem pagamento instantâneo que pode ser feito em poucos segundos. É rápido e seguro.',
      icon: 'Pix' as IconsProps['name'],
      methodKey: PaymentMethodEnum.PIX,
    })
  }

  return paymentMethods
}
