import {
  DatePicker,
  TextArea,
  TimePicker,
} from '@flash-tecnologia/hros-web-ui-v2'

import { InputLabel } from './input-label'
import { InputOptionalTag } from './input-optional-tag'
import { InputRoot } from './input-root'

interface IInputVariants {
  DatePicker: typeof DatePicker
  TimePicker: typeof TimePicker
  TextField: typeof TextArea
}

export interface IInput extends IInputVariants {
  Label: typeof InputLabel
  OptionalTag: typeof InputOptionalTag
  Root: typeof InputRoot
}

export const Input = {
  Label: InputLabel,
  OptionalTag: InputOptionalTag,
  Root: InputRoot,
  DatePicker,
  TimePicker,
  TextField: TextArea,
}
