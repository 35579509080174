import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { useTheme } from 'styled-components'

import { WrapperTitleStyled } from './styles'

interface ITitle {
  children: string
}

export const Title = ({ children }: ITitle) => {
  const theme = useTheme()

  return (
    <WrapperTitleStyled>
      <Typography
        variant="body3"
        weight={700}
        variantColor={theme.colors.secondary50}
      >
        {t('modalAlertConfirm.attention')}
      </Typography>

      <Typography variant="headline6" variantColor={theme.colors.neutral20}>
        {children}
      </Typography>
    </WrapperTitleStyled>
  )
}
