import styled from 'styled-components'

export const SummaryFooterContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.xs2}`};
  width: 100%;
`

type SummaryFooterItemStyledProps = {
  isBetween?: boolean
}

export const SummaryFooterItemStyled = styled.div<SummaryFooterItemStyledProps>`
  align-items: center;
  display: flex;
  justify-content: ${({ isBetween = true }) =>
    isBetween ? 'space-between' : 'flex-start'};
  gap: ${({ theme }) => theme.spacings.xs4};
  width: 100%;
`

export const SummaryFooterItemTextContainerStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`
