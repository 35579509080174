import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

export interface IBilletsLinkButtonProps {
  openNewTab: (_path: string) => void
}

export const BilletsLinkButton: React.FC<IBilletsLinkButtonProps> = ({
  openNewTab,
}) => {
  const { trackEvent } = useEventTracking()

  const onClick = () => {
    trackEvent({
      name: 'FlashOS_BenefitOrders_Checkout_Confirmation_Billets_Click',
    })
    openNewTab('benefits/dashboard/company/payments')
  }

  return (
    <LinkButton variant="neutral" onClick={onClick}>
      {t(
        `order.confirmation.cardGrid.buttons.links.${PaymentMethodEnum.BILLET}`,
      )}
    </LinkButton>
  )
}
