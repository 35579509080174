import { Button, ButtonProps } from '@flash-tecnologia/hros-web-ui-v2'

export interface IDownloadButtonProps extends Omit<ButtonProps, 'size'> {}

export const DownloadButton: React.FC<IDownloadButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button {...props} size={'small'} variant="secondary">
      {children}
    </Button>
  )
}
