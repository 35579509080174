import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { DepositTimeUnitEnum } from 'src/domain/orders/pages/new-order-page/steps/employee-selection/types'
import { useTheme } from 'styled-components'

import { EditAssignmentModeEnum } from '../../../context'
import { TextDepositTimeUnitStyled, TextFieldStyled } from './styles'

type TextFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  depositTimeUnit: DepositTimeUnitEnum
  mode: EditAssignmentModeEnum
  isFieldEnabled: boolean
}

const translateDepositTimeUnit = {
  [DepositTimeUnitEnum.DAY]: 'dia',
  [DepositTimeUnitEnum.MONTH]: 'mês',
}

export function TextField({
  value,
  onChange,
  depositTimeUnit,
  mode,
  isFieldEnabled,
}: TextFieldProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <TextFieldStyled
      value={
        mode !== EditAssignmentModeEnum.SINGLE_EMPLOYEE && !isFieldEnabled
          ? ''
          : value
      }
      onChange={onChange}
      hiddenLabel
      label={
        mode !== EditAssignmentModeEnum.SINGLE_EMPLOYEE && !isFieldEnabled
          ? t(
              'newOrder.employeeSelection.simpleAssignmentEmployeeModal.group.label',
            )
          : ''
      }
      disabled={!isFieldEnabled}
      InputProps={{
        endAdornment: (
          <TextDepositTimeUnitStyled
            variant="body3"
            variantColor={theme.colors.neutral[60]}
          >
            /{translateDepositTimeUnit[depositTimeUnit]}
          </TextDepositTimeUnitStyled>
        ),
      }}
    />
  )
}
