import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { formatOrderId } from 'src/utils/formatOrderId'
import styled, { useTheme } from 'styled-components'

const OrderTitleContainer = styled.div`
  gap: ${({ theme }) => theme.spacings.xs5};
`

export interface IOrderTitleProps {
  paymentMethod: PaymentMethodEnum
  orderId: string
  email?: string
}

export const OrderTitle: React.FC<IOrderTitleProps> = ({
  orderId,
  email,
  paymentMethod,
}) => {
  const theme = useTheme()
  return (
    <OrderTitleContainer>
      <Typography variantColor={theme.colors.neutral20} variant="headline7">
        {t('order.confirmation.cardGrid.title', {
          code: formatOrderId(orderId),
        })}
      </Typography>
      <Typography variantColor={theme.colors.neutral30} variant="body2">
        {t(`order.confirmation.cardGrid.subtitle.${paymentMethod}`)}{' '}
        {email ? (
          <Typography
            tag="b"
            weight={700}
            variantColor={theme.colors.neutral30}
            variant="body2"
          >
            {email}
          </Typography>
        ) : null}
      </Typography>
    </OrderTitleContainer>
  )
}
