import { Divider, Skeleton } from '@flash-tecnologia/hros-web-ui-v2'
import Grid from '@mui/material/Grid'

import { Grid as GridComponent } from '../../../../../components/grid'

export const OrderSummarySkeleton = () => {
  return (
    <GridComponent.CardGrid item p={0} gap={0}>
      <Grid container padding={'20px 16px'}>
        <Skeleton height={21} width={'80%'} variant="rounded" />
      </Grid>

      <Divider orientation="horizontal" />

      <Grid container padding={'16px'} gap={'16px'}>
        <Grid container flexDirection={'row'} gap={'8px'} alignItems={'center'}>
          <Skeleton height={20} width={20} variant="circular" />
          <Skeleton height={12} width={'50%'} variant="rounded" />
        </Grid>
        <Grid container flexDirection={'row'} gap={'8px'} alignItems={'center'}>
          <Skeleton height={20} width={20} variant="circular" />
          <Skeleton height={12} width={'50%'} variant="rounded" />
        </Grid>
        <Grid container flexDirection={'row'} gap={'8px'} alignItems={'center'}>
          <Skeleton height={20} width={20} variant="circular" />
          <Skeleton height={12} width={'50%'} variant="rounded" />
        </Grid>
      </Grid>

      <Divider orientation="horizontal" />

      <Grid padding={'16px'}>
        <Skeleton height={14} width={'40%'} variant="rounded" />
      </Grid>

      <Divider orientation="horizontal" />

      <Grid container padding={'20px 16px'}>
        <Skeleton height={14} width={'60%'} variant="rounded" />
      </Grid>
    </GridComponent.CardGrid>
  )
}
