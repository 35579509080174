import styled, { css } from 'styled-components'

export const EditDaysContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xs2};

  > div {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.xs4};
  }
`

type WrapperTagsStyled = {
  itemCount: number
}

export const WrapperTagsStyled = styled.div<WrapperTagsStyled>`
  display: grid;
  grid-template-columns: ${({ itemCount }) => {
    if (itemCount < 3) {
      return 'repeat(2, minmax(0, 1fr))'
    }

    return 'repeat(3, minmax(0, 140px))'
  }};

  gap: ${({ theme }) => theme.spacings.xs3};

  margin-top: ${({ theme }) => theme.spacings.xs2};
  margin-bottom: ${({ theme }) => theme.spacings.xs};

  ${({ itemCount }) =>
    itemCount >= 3 &&
    css`
      > span {
        width: 100%;
      }
    `};
`

export const ContentTagStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};

  width: 100%;

  img {
    max-width: 16px;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
