import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import formatCurrency from '../formatCurrency'

export const useCurrencyInput = (initialValue?: number) => {
  const [valueInCurrency, setValueInCurrency] = useState('')
  const [value, setValue] = useState(initialValue ?? 0)

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value

      inputValue = inputValue.replace(/[^\d]/g, '') || '0'

      const newValue = parseFloat(inputValue) / 100

      const formatted = formatCurrency(newValue)

      setValue(newValue)
      setValueInCurrency(formatted)
    },
    [],
  )

  const setValueIn = useCallback((value: number) => {
    setValueInCurrency(formatCurrency(value))
    setValue(value)
  }, [])

  const resetValue = useCallback(() => {
    setValueInCurrency('')
    setValue(0)
  }, [])

  useEffect(() => {
    if (typeof initialValue === 'number') {
      setValueInCurrency(formatCurrency(initialValue))
    }
  }, [initialValue])

  return {
    valueInCurrency,
    value,
    handleInputChange,
    setValueIn,
    resetValue,
  }
}
