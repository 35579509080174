import styled from 'styled-components'

export const TextBox = styled.div`
  margin-left: ${({ theme }) => theme.spacings.xs3};
`

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
