import { useContext } from 'react'

import { EditAssignmentsModalContext } from '../context'

export function useEditAssignmentsModal() {
  const context = useContext(EditAssignmentsModalContext)

  if (!context) {
    throw new Error(
      'useEditAssignmentsModal must be used within an EditAssignmentsModalProvider',
    )
  }

  return context
}
