import { useCallback, useContext, useState } from 'react'

import { EmployeeSelectionContext } from '../context'

export function useEmployeeSelection() {
  const context = useContext(EmployeeSelectionContext)

  if (!context) {
    throw new Error(
      'useEmployeeSelectionContext must be used within an EmployeeSelectionProvider',
    )
  }

  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false)

  const openBulkUpdateModal = useCallback(() => {
    setIsBulkUpdateModalOpen(true)
  }, [])

  const closeBulkUpdateModal = useCallback(() => {
    setIsBulkUpdateModalOpen(false)
  }, [])

  return {
    ...context,
    isBulkUpdateModalOpen,
    openBulkUpdateModal,
    closeBulkUpdateModal,
  }
}
