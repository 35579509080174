import { Icons } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const EmojiStackContainerStyled = styled.div<{
  boxShadowColor?: string
}>`
  display: flex;
  align-items: center;

  .icon {
    width: 2rem;
    height: 2rem;
    padding: 6px;
    background-color: ${({ theme }) => theme.colors.tertiary90};
    border-radius: ${({ theme }) => theme.borders.radius.circular};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${({ theme }) => `-${theme.spacings.xs5}`};
    box-shadow: 0 0 0 2px
      ${({ theme, boxShadowColor }) =>
        boxShadowColor ?? theme.colors.neutral100};

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    span {
      pointer-events: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
export const NoEmojiIconStyled = styled(Icons)`
  background-color: transparent !important;
`
