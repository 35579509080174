import { QRCodeSVG } from 'qrcode.react'
import React from 'react'

export interface IQRCodeProps {
  code: string
}

export const QRCode: React.FC<IQRCodeProps> = (props: IQRCodeProps) => {
  return <QRCodeSVG value={props.code} size={210} />
}
