import { Focused as FocusedUI } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type FocusedProps = {
  children: ReactNode
  footer: {
    startActions?: ReactNode[]
    endActions?: ReactNode[]
  }
  breadcrumb: {
    label: string
    route?: string
  }[]
  steps: string[]
  activeStep: number
}

export function Focused({
  children,
  footer,
  breadcrumb,
  steps,
  activeStep,
}: FocusedProps) {
  const navigate = useNavigate()

  return (
    <FocusedUI
      footer={footer}
      routes={breadcrumb}
      navigate={navigate}
      stepper={{ disableClick: true, steps, activeStep }}
    >
      {children}
    </FocusedUI>
  )
}
