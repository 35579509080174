import { EmptyState, Icons } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'

export const ErrorBoundary = () => {
  const { t } = useTranslation()
  return (
    <EmptyState
      type="error"
      title={t('errorBoundary.title')}
      description={t('errorBoundary.description')}
      buttonText={
        <>
          <span> {t('errorBoundary.button')}</span>
          <Icons name="IconHome" />
        </>
      }
      buttonProps={{
        size: 'large',
        onClick: () => window.location.replace('/home'),
      }}
    />
  )
}
