import {
  Button,
  LinkButton,
  Modal,
  Skeleton,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Employee } from '../../steps/employee-selection/types'
import { BenefitEdit } from './components/benefit-edit'
import { EditAssignmentModeEnum, EditAssignmentsModalProvider } from './context'
import { useEditAssignmentsModal } from './hooks/use-edit-assignments-modal'
import { BoxAdornmentStyled, ContentStyled } from './styles'

type EditAssignmentsModalProps = {
  mode: EditAssignmentModeEnum
  employees: Employee[]
  onClose: () => void
}

const skeletonRows = Array.from({ length: 4 })

function EditAssignmentsModalContent() {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    onClose,
    applyAsDefault,
    toggleApplyAsDefault,
    isLoadingBenefits,
    isLoadingUpdate,
    benefitsAssignments,
    handleConfirm,
    hasUpdates,
    employees,
    mode,
  } = useEditAssignmentsModal()

  const modalHeaders = useMemo(() => {
    if (mode === EditAssignmentModeEnum.SINGLE_EMPLOYEE) {
      const [employee] = employees

      return {
        title: `Alterar valores de benefício de ${employee.name}`,
        description:
          'Selecione os benefícios que deseja alterar e defina um novo valor para eles.',
      }
    }

    if (mode === EditAssignmentModeEnum.MULTIPLE_EMPLOYEES) {
      return {
        title:
          employees.length > 1
            ? 'Alterar valores de benefício das pessoas selecionadas'
            : 'Alterar valores de benefício da pessoa selecionada',
        description:
          'Selecione os benefícios que deseja alterar e defina um novo valor para eles.',
      }
    }

    return {
      title:
        employees.length > 1
          ? `Alterar valores de benefício de ${employees.length} pessoas sem atribuição`
          : 'Alterar valores de benefício de uma pessoa sem atribuição',
      description:
        'Selecione os benefícios que deseja alterar e defina um novo valor para eles.',
    }
  }, [employees, mode])

  return (
    <Modal.Root open onClose={onClose} size="sm">
      <>
        <Modal.Header
          title={modalHeaders.title}
          description={modalHeaders.description}
        />

        <Modal.Content>
          {hasUpdates && (
            <BoxAdornmentStyled
              title={t(
                'newOrder.employeeSelection.simpleAssignmentEmployeeModal.adoptNewValues.title',
              )}
              description={t(
                'newOrder.employeeSelection.simpleAssignmentEmployeeModal.adoptNewValues.description',
              )}
              leftAdornment={
                <Toggle
                  checked={applyAsDefault}
                  onChange={toggleApplyAsDefault}
                />
              }
            />
          )}

          <ContentStyled>
            {isLoadingBenefits &&
              skeletonRows.map((_, index) => (
                <Skeleton
                  style={{ marginBottom: theme.spacings.xs2 }}
                  key={index}
                  height={54}
                  variant="rounded"
                />
              ))}

            {!isLoadingBenefits &&
              benefitsAssignments.map((benefit) => (
                <BenefitEdit key={benefit.id} benefit={benefit} />
              ))}
          </ContentStyled>
        </Modal.Content>

        <Modal.Footer>
          <LinkButton variant="neutral" onClick={onClose}>
            {t(
              'newOrder.employeeSelection.simpleAssignmentEmployeeModal.btnCancel',
            )}
          </LinkButton>

          <Button
            variant="primary"
            size="medium"
            onClick={handleConfirm}
            loading={isLoadingUpdate}
          >
            {t(
              'newOrder.employeeSelection.simpleAssignmentEmployeeModal.btnConfirm',
            )}
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

export function EditAssignmentsModal({
  mode,
  onClose,
  employees,
}: EditAssignmentsModalProps) {
  return (
    <EditAssignmentsModalProvider
      mode={mode}
      employees={employees}
      onClose={onClose}
    >
      <EditAssignmentsModalContent />
    </EditAssignmentsModalProvider>
  )
}
