import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

import { IPaymentMethod, PaymentMethod } from './payment-method'
import { PaymentMethodContainer } from './styles'

interface IPaymentMethodsProps {
  value: PaymentMethodEnum | undefined
  onChange: (_value: PaymentMethodEnum) => void
  paymentMethods: IPaymentMethod[]
  children?: React.ReactNode
}

const PaymentMethods: React.FC<IPaymentMethodsProps> = ({
  paymentMethods,
  onChange,
  value,
  children,
}) => {
  const theme = useTheme()
  return (
    <PaymentMethodContainer>
      <Typography variant={'headline8'} variantColor={theme.colors.neutral[20]}>
        {t('order.paymentMethods.title')}
      </Typography>
      {paymentMethods.map((method: IPaymentMethod) => (
        <PaymentMethod
          {...method}
          key={method.methodKey}
          onClick={onChange}
          isSelected={method.methodKey === value}
        />
      ))}

      {children}
    </PaymentMethodContainer>
  )
}

export default PaymentMethods
