import React from 'react'

import { ICheckoutMethod } from '../../checkout-method.interface'
import { CheckoutDatesInputs } from '../dates-inputs'
import { BilletDueDate, IBilletDueDateProps } from './billet-due-date'

export interface IBilletCheckoutProps extends ICheckoutMethod {}

export const BilletCheckout: React.FC<IBilletCheckoutProps> = ({
  disabled = false,
  onDepositDateChange,
  depositDate,
  dueDate,
  paymentMethod,
}) => {
  const DueDateInput: React.ReactElement<IBilletDueDateProps> =
    React.useMemo(() => {
      if (!disabled) {
        return (
          <BilletDueDate
            value={dueDate}
            disabled={true}
            onDateChange={() => {}}
          />
        )
      }
      return <></>
    }, [dueDate, disabled, depositDate])

  return (
    <CheckoutDatesInputs
      onDateChange={onDepositDateChange}
      disabled={disabled}
      value={depositDate}
      dueDateInput={DueDateInput}
      paymentMethod={paymentMethod}
    />
  )
}
