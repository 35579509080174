import { ContainerStyled } from './styles'

export interface IEmojiListItemProps {
  imgSrc: string
  width?: number
  className?: string
}

export const Emoji: React.FC<IEmojiListItemProps> = ({
  imgSrc,
  width = 32,
  className,
}: IEmojiListItemProps) => {
  return (
    <ContainerStyled $width={width} className={className}>
      <img src={imgSrc} alt="emoji" />
    </ContainerStyled>
  )
}
