import { BoxAdornment } from './styles'

export interface IRadioCardRoot {
  children: React.ReactNode
  checked: boolean
  disabled: boolean
  onClick: () => void
}

const RadioCardRoot: React.FC<IRadioCardRoot> = ({
  children,
  checked,
  disabled,
  onClick,
}) => (
  <BoxAdornment checked={checked} disabled={disabled} onClick={onClick}>
    {children}
  </BoxAdornment>
)

export default RadioCardRoot
