import styled from 'styled-components'

export const TitleRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`

export const ListTitle = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`
