import { Group } from '../types'

export const searchEmployeeInGroups = (groups: Group[], searchTerm: string) => {
  const regex = new RegExp(searchTerm, 'i')

  return groups.reduce((acc: Group[], group: Group) => {
    const filteredEmployees = group.employees.filter(
      (employee) => regex.test(employee.name) || regex.test(employee.document),
    )

    if (filteredEmployees.length > 0) {
      acc.push({ ...group, employees: filteredEmployees })
    }

    return acc
  }, [])
}
