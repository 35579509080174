import {
  Button,
  ButtonProps,
  Icons,
  IconTypes,
} from '@flash-tecnologia/hros-web-ui-v2'

export type StepNavigationButtonProps = {
  text: string
  onClick?: () => void
  isLoading?: boolean
  showIcon?: boolean
  iconName?: IconTypes
  iconPosition?: 'left' | 'right'
  disabled?: boolean
}

export function StepNavigationButton(
  props: StepNavigationButtonProps & ButtonProps,
) {
  const {
    text,
    iconName = 'IconArrowRight',
    iconPosition = 'right',
    isLoading = false,
    onClick,
    showIcon = true,
    variant,
    size = 'medium',
    disabled = false,
  } = props

  return (
    <Button
      variant={variant}
      size={size}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled}
    >
      {showIcon && iconPosition === 'left' && (
        <Icons name={iconName} fill="transparent" />
      )}

      {text}

      {showIcon && iconPosition === 'right' && (
        <Icons name={iconName} fill="transparent" />
      )}
    </Button>
  )
}
