import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { trpc } from 'src/api/client'
import { useToast } from 'src/utils/hooks/useToast'

import {
  Benefit,
  DepositTimeUnitEnum,
  Employee,
} from '../../steps/employee-selection/types'
import { useNewOrderStore } from '../../store/use-new-order-store'

export enum EditAssignmentModeEnum {
  SINGLE_EMPLOYEE = 'single_employee',
  MULTIPLE_EMPLOYEES = 'multiple_employees',
  UNASSIGNED_EMPLOYEES = 'unassigned_employees',
}

export type BenefitAssignment = {
  id: string
  value: number
  name: string
  emoji: string
  depositTimeUnit: DepositTimeUnitEnum
  topupDepositEnabled: boolean
}

type EditAssignmentsModalContextType = {
  onClose: () => void
  benefitsAssignments: BenefitAssignment[]
  isLoadingBenefits: boolean
  isLoadingUpdate: boolean
  applyAsDefault: boolean
  hasUpdates: boolean
  toggleApplyAsDefault: () => void
  updateBenefit: (benefit: BenefitAssignment, value: number | null) => void
  handleConfirm: () => void
  employees: Employee[]
  mode: EditAssignmentModeEnum
}

export type EditAssignmentsProviderModalProps = {
  children: ReactNode
  onClose: () => void
  mode: EditAssignmentModeEnum
  employees: Employee[]
}

export const EditAssignmentsModalContext = createContext(
  {} as EditAssignmentsModalContextType,
)

export function EditAssignmentsModalProvider({
  children,
  onClose,
  mode,
  employees,
}: EditAssignmentsProviderModalProps) {
  const { toastSuccess, toastError } = useToast()
  const {
    updateEmployeeBenefitValue,
    updateSelectedEmployeesBenefitValue,
    setBenefitsForSelectedEmployees,
  } = useNewOrderStore()

  const [updatedBenefits, setUpdatedBenefits] = useState<Benefit[]>([])
  const [applyAsDefault, setApplyAsDefault] = useState(false)

  const { data: benefits = [], isLoading: isLoadingBenefits } =
    trpc.simpleAssignment.getActiveBenefits.useQuery()

  const updateAssignmentsMutate =
    trpc.simpleAssignment.upsertAssignments.useMutation()

  const benefitsAssignments = useMemo(() => {
    const employeeBenefits =
      mode === EditAssignmentModeEnum.SINGLE_EMPLOYEE
        ? (employees[0]?.benefits ?? [])
        : []

    const mapEmployeeBenefits = new Map(
      employeeBenefits.map((benefit) => [benefit.id, benefit]),
    )

    const newList = benefits.map((benefit) => ({
      ...benefit,
      value: mapEmployeeBenefits.get(benefit.id)?.value ?? 0,
    }))

    return mode === EditAssignmentModeEnum.SINGLE_EMPLOYEE
      ? newList.sort((a, b) => (b.value ? 1 : 0) - (a.value ? 1 : 0))
      : newList
  }, [benefits, employees, mode])

  const hasUpdates = useMemo(
    () => updatedBenefits.length > 0,
    [updatedBenefits.length],
  )

  const toggleApplyAsDefault = useCallback(() => {
    setApplyAsDefault((prev) => !prev)
  }, [])

  const updateBenefit = useCallback(
    (benefitAssignment: BenefitAssignment, value: number | null) => {
      setUpdatedBenefits((prev) => {
        if (value === null) {
          return prev.filter((benefit) => benefit.id !== benefitAssignment.id)
        }

        const existingIndex = prev.findIndex(
          (benefit) => benefit.id === benefitAssignment.id,
        )

        if (existingIndex !== -1) {
          const updated = [...prev]

          updated[existingIndex] = { ...updated[existingIndex], value }

          return updated
        }

        return [
          ...prev,
          {
            id: benefitAssignment.id,
            name: benefitAssignment.name,
            value,
            url: benefitAssignment.emoji,
            depositTimeUnit:
              benefitAssignment.depositTimeUnit as DepositTimeUnitEnum,
            topupDepositEnabled: benefitAssignment.topupDepositEnabled,
          },
        ]
      })
    },
    [],
  )

  const handleConfirm = useCallback(() => {
    if (updatedBenefits.length === 0) {
      return onClose()
    }

    const applyUpdates = () => {
      if (mode === EditAssignmentModeEnum.SINGLE_EMPLOYEE) {
        const [employee] = employees

        updateEmployeeBenefitValue({
          employeeId: employee.id,
          updatedBenefits,
        })
      } else if (mode === EditAssignmentModeEnum.MULTIPLE_EMPLOYEES) {
        updateSelectedEmployeesBenefitValue(updatedBenefits)
      } else {
        setBenefitsForSelectedEmployees(employees, updatedBenefits)
      }

      onClose()
    }

    const showToastSuccess = () => {
      const getTitle = () => {
        if (
          mode === EditAssignmentModeEnum.MULTIPLE_EMPLOYEES ||
          mode === EditAssignmentModeEnum.UNASSIGNED_EMPLOYEES
        ) {
          if (employees.length > 1) {
            return `Valores de benefício atualizados com sucesso para ${employees.length} pessoas!`
          }

          return 'Valores de benefícios atualizado com sucesso para 1 pessoa!'
        }

        return 'Valores de benefício atualizados com sucesso!'
      }

      const getDescription = () => {
        if (
          mode === EditAssignmentModeEnum.MULTIPLE_EMPLOYEES ||
          mode === EditAssignmentModeEnum.UNASSIGNED_EMPLOYEES
        ) {
          if (applyAsDefault) {
            return employees.length > 1
              ? 'A partir dos próximos pedidos esses valores serão os valores padrão das pessoas selecionadas.'
              : 'A partir dos próximos pedidos esses valores serão os valores padrão da pessoa selecionada.'
          }

          return employees.length > 1
            ? 'Os valores de benefícios para este pedido das pessoas selecionadas foram alterados com sucesso.'
            : 'Os valores de benefícios para este pedido da pessoa selecionada foi alterado com sucesso.'
        }

        const [employee] = employees

        return applyAsDefault
          ? `A partir dos próximos pedidos esses valores serão os valores padrão de ${employee.name}.`
          : `Os valores de benefícios de ${employee.name} para este pedido foram alterados com sucesso.`
      }

      toastSuccess({
        title: getTitle(),
        description: getDescription(),
      })
    }

    if (applyAsDefault) {
      const employeeIds =
        mode === EditAssignmentModeEnum.SINGLE_EMPLOYEE
          ? [employees[0].id]
          : employees.map((emp) => emp.id)

      updateAssignmentsMutate.mutate(
        updatedBenefits.map((benefit) => ({
          employeeIds,
          benefitId: benefit.id,
          value: benefit.value,
        })),
        {
          onSuccess: () => {
            applyUpdates()
            showToastSuccess()
          },
          onError: () => {
            toastError({
              title: 'Tivemos um erro alterar os valores de benefício',
              description:
                'Tente novamente mais tarde, ou entre em contato com a nossa Central de Ajuda.',
            })
          },
        },
      )
    } else {
      showToastSuccess()
      applyUpdates()
    }
  }, [
    applyAsDefault,
    employees,
    mode,
    onClose,
    setBenefitsForSelectedEmployees,
    toastError,
    toastSuccess,
    updateAssignmentsMutate,
    updateEmployeeBenefitValue,
    updateSelectedEmployeesBenefitValue,
    updatedBenefits,
  ])

  useEffect(() => {
    if (!hasUpdates) {
      setApplyAsDefault(false)
    }
  }, [hasUpdates])

  return (
    <EditAssignmentsModalContext.Provider
      value={{
        onClose,
        benefitsAssignments,
        isLoadingBenefits,
        isLoadingUpdate: updateAssignmentsMutate.isLoading,
        hasUpdates,
        applyAsDefault,
        toggleApplyAsDefault,
        updateBenefit,
        handleConfirm,
        employees,
        mode,
      }}
    >
      {children}
    </EditAssignmentsModalContext.Provider>
  )
}
