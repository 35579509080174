import { useTranslation } from 'react-i18next'
import { EmojiStack } from 'src/components/emoji-stack'
import { useTheme } from 'styled-components'

import { Employee, Group } from '../types'

type EmojisCellProps = {
  groupOrEmployee: Group | Employee
  isEmployee?: boolean
}

export function EmojisCell({
  groupOrEmployee,
  isEmployee = false,
}: EmojisCellProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <EmojiStack
      emojis={groupOrEmployee.benefits}
      boxShadowColor={isEmployee ? theme.colors.neutral95 : undefined}
      noEmojisMessage={t('newOrder.employeeSelection.table.noBenefits')}
    />
  )
}
