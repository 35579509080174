import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const TextFieldStyled = styled(TextField)`
  width: 240px;
`

export const TextDepositTimeUnitStyled = styled(Typography)`
  white-space: nowrap;
`
