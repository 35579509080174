import styled from 'styled-components'

export interface IStyledDivProps extends React.HTMLAttributes<HTMLDivElement> {
  inline?: boolean
}

const StyledDiv = styled.div<IStyledDivProps>`
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
`

export interface IRootProps extends IStyledDivProps {}

export const RootDiv: React.FC<IRootProps> = ({ children, ...rest }) => {
  return <StyledDiv {...rest}>{children}</StyledDiv>
}
