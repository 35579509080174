import styled from 'styled-components'

export const TotalPartialContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.secondary99};
  padding: ${({ theme }) => theme.spacings.xs2};

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.xs5};
  }
`
