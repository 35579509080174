import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useTheme } from 'styled-components'

const InsufficientBalanceCard: React.FC = () => {
  const theme = useTheme()
  return (
    <Grid
      container
      display={'flex'}
      alignItems={'center'}
      padding={theme.spacings.xs}
      gap={theme.spacings.xs}
      direction={'row'}
      borderRadius={theme.borders.radius.m}
      border={`${theme.borders.width.xs2} solid ${theme.colors.feedback.error70}`}
      maxWidth={'900px'}
    >
      <ShapeIcon
        size={48}
        variant="error"
        strokeWidth="2"
        name={'IconWalletOff'}
      />
      <Grid container xs gap={theme.spacings.xs2}>
        <Typography
          variant="body3"
          weight={600}
          variantColor={theme.colors.feedback.error10}
        >
          Você não possui saldo suficiente de Flash Cash
        </Typography>
        <Typography variant="body4">
          Não há saldo suficiente em Flash Cash para realizar o pedido de
          benefício. Realize uma recarga ou altere o método de pagamento para
          continuar.
        </Typography>
        {/* <Grid2 container direction={'row'} gap={theme.spacings.xs2}>
          <LinkButton variant={'neutral'} onClick={() => {}}>
            Recarregar
          </LinkButton>
          <LinkButton variant={'neutral'} onClick={() => {}}>
            Cancelar Pedido
          </LinkButton>
        </Grid2> */}
      </Grid>
    </Grid>
  )
}
export default InsufficientBalanceCard
