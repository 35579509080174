import { Radio } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'

interface IRadioCardInput {
  checked: boolean
  disabled: boolean
}

const radioStyle = {
  padding: '0px',
  paddingRight: '9px',
}

const RadioCardInput: React.FC<IRadioCardInput> = ({ checked, disabled }) => (
  <Radio
    checked={checked}
    disabled={disabled}
    id={'radio-card'}
    name={'radio-card'}
    style={radioStyle}
  />
)

export default RadioCardInput
