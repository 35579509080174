import { legacyGetFromLS } from '@flash-tecnologia/hros-web-utility'
import { useMemo } from 'react'

export const useLegacySelectedCompany = () => {
  const { companyId: legacyCompanyId, companies } =
    legacyGetFromLS('clientData')

  const company = useMemo(() => {
    return (
      companies?.find(({ _id }: { _id: string }) => _id === legacyCompanyId) ||
      {}
    )
  }, [legacyCompanyId])

  return company
}
