import { Accordion, Divider, ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'

import { InstructionSection } from '../instruction-section'
import { buildAccordionText } from '../utils/buildAccordionText'

export function SpreadsheetIntructions() {
  const { t } = useTranslation()

  return (
    <Accordion
      variant="default"
      title={t('newOrder.flashSpreadsheet.accordion.title')}
      description={t('newOrder.flashSpreadsheet.accordion.description')}
      leftIcon={
        <ShapeIcon
          variant="default"
          name="IconListDetails"
          size={42}
          stroke="default"
          style={{ flex: '1 0 auto' }}
        />
      }
    >
      {buildAccordionText().map(
        (
          { title, section, icon, description, tag, tagVariant },
          index,
          array,
        ) => (
          <div key={index}>
            <InstructionSection
              title={title}
              section={section}
              tag={tag}
              description={description}
              icon={icon}
              tagVariant={tagVariant}
            />
            {index < array.length - 1 && <Divider orientation="horizontal" />}
          </div>
        ),
      )}
    </Accordion>
  )
}
