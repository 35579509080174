import { dayjs, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'
import { Grid } from 'src/components/grid'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

import { OrderStatusEnum } from '../../../../../../components/order-confirmation-card/components/header/components/tags/order-status-tag'
import { IOrderMethodConfirmation } from '../../order-method-confirmation.interface'
import { OrderHeader } from '../order-header'
import { OrderInfos } from '../order-infos/order-infos'
import { OrderLinks as OrderLinksComponent } from '../order-links'
import { OrderReminder } from '../order-reminder'
import { OrderTitle } from '../order-title'
import { BilletDueDateInfo } from './components/billet-due-date-info'
import { BilletsLinkButton } from './components/billets-link-button'

interface IBilletConfirtmationProps extends IOrderMethodConfirmation {
  dueDate: dayjs.Dayjs
  email: string
}

export const BilletConfirmation: React.FC<IBilletConfirtmationProps> = ({
  orderId,
  amount,
  depositDate,
  dueDate,
  email,
  openNewTab,
  OrderLinks = OrderLinksComponent,
}) => {
  const theme = useTheme()
  const DueDateInfo: React.ReactElement<typeof BilletDueDateInfo> =
    React.useMemo(() => <BilletDueDateInfo dueDate={dueDate} />, [dueDate])

  const BilletsLink: React.ReactElement<typeof BilletsLinkButton> =
    React.useMemo(() => <BilletsLinkButton openNewTab={openNewTab} />, [])

  return (
    <Grid.Container container rowGap={{ xs: theme.spacings.s }}>
      <Grid.SideGrid item xs={12} xl={3} pr={{ xl: theme.spacings.s }}>
        <Typography variant="headline7" variantColor={theme.colors.neutral10}>
          {t(`order.confirmation.sideGrid.${PaymentMethodEnum.BILLET}.title`)}
        </Typography>
        <Typography variant="body3" variantColor={theme.colors.neutral20}>
          {t(
            `order.confirmation.sideGrid.${PaymentMethodEnum.BILLET}.subtitle`,
          )}
        </Typography>
      </Grid.SideGrid>
      <Grid.CardGrid item xs={12} xl={9}>
        <OrderHeader
          status={OrderStatusEnum.PENDING}
          paymentMethod={PaymentMethodEnum.BILLET}
        />
        <OrderTitle
          paymentMethod={PaymentMethodEnum.BILLET}
          orderId={orderId}
          email={email}
        />
        <OrderInfos
          amount={amount}
          depositDate={depositDate}
          dueDateInfo={DueDateInfo}
        />
        <OrderLinks extraLink={BilletsLink} openNewTab={openNewTab} />
        <OrderReminder
          depositDate={depositDate}
          paymentMethod={PaymentMethodEnum.BILLET}
        />
      </Grid.CardGrid>
    </Grid.Container>
  )
}
