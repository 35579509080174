import { useMemo } from 'react'

import {
  MAX_VISIBLE_EMPLOYEES,
  OVERSCAN_COUNT_LARGE,
  OVERSCAN_COUNT_SMALL,
} from '../../config/constants'
import { useEmployeeSelection } from '../../hooks/use-employee-selection'
import { Employee } from '../../types'

type UseVirtualListProps = {
  employees: Employee[]
}

export function useVirtualList({ employees }: UseVirtualListProps) {
  const { getVisibleTableRowHeight } = useEmployeeSelection()

  const elementHeight = getVisibleTableRowHeight()

  const totalEmployees = useMemo(() => employees.length, [employees.length])

  const height = useMemo(() => {
    if (totalEmployees > MAX_VISIBLE_EMPLOYEES) {
      return elementHeight * MAX_VISIBLE_EMPLOYEES
    }

    return elementHeight * totalEmployees
  }, [elementHeight, totalEmployees])

  const overscanCount = useMemo(
    () =>
      totalEmployees > MAX_VISIBLE_EMPLOYEES
        ? OVERSCAN_COUNT_LARGE
        : OVERSCAN_COUNT_SMALL,
    [totalEmployees],
  )

  return {
    height,
    totalEmployees,
    elementHeight,
    overscanCount,
  }
}
