import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'
import { OrderConfirmationCard } from 'src/components/order-confirmation-card'
import styled from 'styled-components'

import { BilletDueDateInfo } from '../billet-confirmation/components/billet-due-date-info'
import { PixDueDateInfo } from '../pix-confirmation/components/pix-due-date-info'

interface IOrderInfosProps {
  depositDate: dayjs.Dayjs
  amount: number
  dueDateInfo?: React.ReactElement<
    typeof BilletDueDateInfo | typeof PixDueDateInfo
  >
}

const OrderInfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.s};
  flex-wrap: wrap;
`

export const OrderInfos: React.FC<IOrderInfosProps> = ({
  amount,
  depositDate,
  dueDateInfo: DueDateInfo,
}) => {
  const depositDateString = dayjs(depositDate).format('DD/MM/YYYY')

  return (
    <OrderInfosContainer>
      <OrderConfirmationCard.Content.Info
        label={t('order.confirmation.cardGrid.infos.amount')}
        value={t(`order.checkout.orderSummary.totalAmount.value`, {
          value: amount,
        })}
      />
      {DueDateInfo}
      <OrderConfirmationCard.Content.Info
        label={t('order.confirmation.cardGrid.infos.depositDate')}
        value={depositDateString}
        variant={'secondary'}
      />
    </OrderInfosContainer>
  )
}
