export const cpfMask = (cpf = '') => {
  let maskedCpf = cpf
  maskedCpf = maskedCpf.replace(/\D/g, '')
  maskedCpf = maskedCpf.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{1,3})$/,
    '$1.$2.$3-$4',
  )
  maskedCpf = maskedCpf.replace(/^(\d{3})(\d{3})(\d{1,3})$/, '$1.$2.$3')
  maskedCpf = maskedCpf.replace(/^(\d{3})(\d{1,3})$/, '$1.$2')
  return maskedCpf
}

export const cnpjMask = (cnpj: string) => {
  if (!cnpj) {
    return '-'
  }

  const cnpjFormatted = cnpj.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  )

  return cnpjFormatted
}

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}
