import {
  Button,
  Divider,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'
import { Grid } from 'src/components/grid'
import { QRCode } from 'src/components/qr-code'
import styled, { useTheme } from 'styled-components'

import { ListItem, OrderedList } from './components/ordered-list'

const PixCodeTextContainer = styled.div`
  overflow-wrap: anywhere;
`

const CopyButton = styled(Button)`
  min-width: max-content;
`

export interface IPixPaymentInfoProps {
  code: string
}

export const PixPaymentInfo: React.FC<IPixPaymentInfoProps> = ({ code }) => {
  const theme = useTheme()

  const onCopyButtonClicked = React.useCallback(() => {
    navigator.clipboard.writeText(code)
  }, [code])

  return (
    <Grid.Item container gap={theme.spacings.xs}>
      <Divider orientation="horizontal" />
      <Grid.Item display="inline-block" container gap={theme.spacings.xs}>
        <Grid.Item>
          <Typography variant="headline8" variantColor={theme.colors.neutral20}>
            {t(`order.confirmation.pixInfo.title`)}
          </Typography>
        </Grid.Item>
        <Grid.Item>
          <Typography variant="body3" variantColor={theme.colors.neutral30}>
            {t(`order.confirmation.pixInfo.description`)}
          </Typography>
        </Grid.Item>
      </Grid.Item>

      <Grid.Item container direction={'row'} gap={theme.spacings.xs}>
        <Grid.Item>
          <QRCode code={code} />
        </Grid.Item>
        <Grid.Item container md gap={theme.spacings.xs}>
          <Grid.Item container direction={'column'} gap={theme.spacings.xs4}>
            <Grid.Item>
              <Typography
                variant="body3"
                weight={700}
                variantColor={theme.colors.neutral20}
              >
                {t(`order.confirmation.pixInfo.subtitle`)}
              </Typography>
            </Grid.Item>
            <Grid.Item
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              gap={theme.spacings.xs}
            >
              <PixCodeTextContainer>
                <Typography
                  variant="caption"
                  variantColor={theme.colors.neutral30}
                >
                  {code}
                </Typography>
              </PixCodeTextContainer>
              <CopyButton
                size="small"
                variant="secondary"
                onClick={onCopyButtonClicked}
              >
                <Icons name="IconCopy" />{' '}
                {t(`order.confirmation.pixInfo.copyButton`)}
              </CopyButton>
            </Grid.Item>
          </Grid.Item>
          <Grid.Item container gap={theme.spacings.xs4}>
            <Grid.Item md={12}>
              <Typography
                variant="body3"
                weight={700}
                variantColor={theme.colors.neutral20}
              >
                {t(`order.confirmation.pixInfo.stepsTitle`)}
              </Typography>
            </Grid.Item>
            <Grid.Item md={12}>
              <OrderedList>
                <ListItem>{t(`order.confirmation.pixInfo.steps.0`)}</ListItem>
                <ListItem>{t(`order.confirmation.pixInfo.steps.1`)}</ListItem>
                <ListItem>{t(`order.confirmation.pixInfo.steps.2`)}</ListItem>
              </OrderedList>
            </Grid.Item>
          </Grid.Item>
        </Grid.Item>
      </Grid.Item>
    </Grid.Item>
  )
}
