import { PillButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditAssignmentsModal } from '../../../components/edit-assignments-modal'
import { EditAssignmentModeEnum } from '../../../components/edit-assignments-modal/context'
import { Employee } from '../types'

type ActionCellProps = {
  groupOrEmployee?: Employee
  isEmployee?: boolean
  expandable?: {
    isOpen: boolean
    handleToogle: (status: boolean) => void
  }
}

export function ActionCell({
  groupOrEmployee,
  isEmployee = false,
  expandable,
}: ActionCellProps) {
  const { t } = useTranslation()

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  if (isEmployee && groupOrEmployee) {
    return (
      <>
        <Tooltip
          title={t('newOrder.employeeSelection.table.actionCell.tooltip')}
          placement="bottom-end"
        >
          <div>
            <PillButton
              icon="IconEdit"
              size="small"
              variant="default"
              onClick={() => setIsOpenModal(true)}
            />
          </div>
        </Tooltip>
        {isOpenModal && (
          <EditAssignmentsModal
            mode={EditAssignmentModeEnum.SINGLE_EMPLOYEE}
            employees={[groupOrEmployee]}
            onClose={() => setIsOpenModal(false)}
          />
        )}
      </>
    )
  }

  return (
    <PillButton
      icon={expandable?.isOpen ? 'IconChevronUp' : 'IconChevronDown'}
      size="small"
      variant="default"
      onClick={() => expandable?.handleToogle(!expandable.isOpen)}
    />
  )
}
