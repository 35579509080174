import { IconsProps, ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2'
import { Grid } from 'src/components/grid'
import { RadioCard } from 'src/components/radio-card'
import { RadioCardTextTypeEnum } from 'src/components/radio-card/radio-card-text/radio-card-text'
import { useTheme } from 'styled-components'

type OrderMethodRadioCardProps = {
  description: string
  disabled?: boolean
  icon: IconsProps['name']
  onSelect: () => void
  selected: boolean
  title: string
}

export const OrderMethodRadioCard = ({
  description,
  disabled = false,
  icon,
  onSelect,
  selected,
  title,
}: OrderMethodRadioCardProps) => {
  const theme = useTheme()

  return (
    <RadioCard.Root checked={selected} onClick={onSelect} disabled={disabled}>
      <RadioCard.Radio checked={selected} disabled={disabled} />

      <ShapeIcon
        name={icon}
        variant="default"
        size={48}
        color="default"
        stroke="default"
        strokeWidth="2"
        width={48}
        height={48}
      />
      <Grid.Container
        marginLeft={theme.spacings.xs3}
        minWidth="none"
        display="inline"
      >
        <RadioCard.Text
          checked={selected}
          disabled={disabled}
          text={title}
          textVariant="body3"
          type={RadioCardTextTypeEnum.TITLE}
        />
        <RadioCard.Text
          checked={selected}
          disabled={disabled}
          text={description}
          textVariant="body3"
        />
      </Grid.Container>
    </RadioCard.Root>
  )
}
