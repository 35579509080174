import styled from 'styled-components'

export const MainContainerStyled = styled.div`
  padding: ${({ theme }) => theme.spacings.xs2} 0;
`

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`

export const DescriptionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
  margin-left: ${({ theme }) => theme.spacings.xs5};

  &[data-section='phone'] span:first-child {
    font-weight: 700;
  }
`
