import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

interface IPaymentMethodHeader {
  isTopup: boolean
}

const PaymentMethodHeader = ({ isTopup }: IPaymentMethodHeader) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const title = isTopup
    ? t('order.paymentMethods.header.titleTopup')
    : t('order.paymentMethods.header.title')

  return (
    <Grid container rowGap={theme.spacings.xs5} direction="column">
      <Typography variant={'headline8'} variantColor={theme.colors.neutral20}>
        {title}
      </Typography>

      {!isTopup && (
        <Typography variant={'body3'} variantColor={theme.colors.neutral30}>
          {t('order.paymentMethods.header.subtitle')}
        </Typography>
      )}
    </Grid>
  )
}

export default PaymentMethodHeader
