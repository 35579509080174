import { Icons, Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

interface IPaymentMethodTagProps {
  paymentMethod: PaymentMethodEnum
}

const paymentMethodTagMap: Record<
  PaymentMethodEnum,
  React.ComponentProps<typeof Icons>['name']
> = {
  [PaymentMethodEnum.BILLET]: 'BarcodeMethod',
  [PaymentMethodEnum.FLASH_CASH]: 'IconWallet',
  [PaymentMethodEnum.PIX]: 'Pix',
}

export const PaymentMethodTag: React.FC<IPaymentMethodTagProps> = ({
  paymentMethod,
}) => {
  const theme = useTheme()
  const iconName = paymentMethodTagMap[paymentMethod]
  const text = t('order.paymentMethods.' + paymentMethod)
  return (
    <Tag variant={'gray'} as="label">
      <Icons name={iconName} size={16} stroke={theme.colors.neutral[10]} />
      <Typography variant="body3">{text}</Typography>
    </Tag>
  )
}
