import { DownloadButton } from './components/download-button'
import { OrderLinkButton } from './components/order-link-button'

interface IConfirmationButtons {
  LinkButton: typeof OrderLinkButton
  DownloadButton: typeof DownloadButton
}

export const ConfirmationButtons: IConfirmationButtons = {
  LinkButton: OrderLinkButton,
  DownloadButton,
}
