import { Tag } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'

export type BalancesTypes = 'plastic' | 'virtual'

export interface IBalances {
  label: BalancesTypes
  value: number
}

export interface IBalancesTagProps extends IBalances {
  variant: 'green' | 'error'
}

export const BalancesTag: React.FC<IBalancesTagProps> = ({
  label,
  value,
  variant,
}) => {
  return (
    <Tag variant={variant} as="label" size="large">
      {t(`order.balances.${label}`)}: {t(`order.balances.value`, { value })}
    </Tag>
  )
}
