import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'
import { useTheme } from 'styled-components'

export type IBilletDueDateProps = React.ComponentProps<
  typeof Checkout.Content.Input.DatePicker
>

export const BilletDueDate: React.FC<IBilletDueDateProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Checkout.Content.Input.Root
      item
      sm={12}
      xl={6}
      pl={{ xl: theme.spacings.xs3 }}
    >
      <Checkout.Content.Input.Label>
        {t('order.checkoutDates.availabilityDate.billetDueDateInput.label')}
      </Checkout.Content.Input.Label>
      <Checkout.Content.Input.DatePicker
        {...props}
        id="billetDueDateInput"
        label={t(
          'order.checkoutDates.availabilityDate.billetDueDateInput.inputPlaceholder',
        )}
      />
    </Checkout.Content.Input.Root>
  )
}
