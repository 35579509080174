import {
  Icons,
  IconTypes,
  Tag,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { TagStyledProps } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Tag/styled'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import {
  ContainerStyled,
  DescriptionContainerStyled,
  MainContainerStyled,
} from './styles'

export type InstructionSectionType = {
  title: string
  section: string
  icon: IconTypes
  tag: string
  tagVariant: TagStyledProps['variant']
  description?: []
}

export function InstructionSection({
  title,
  section,
  icon,
  tag,
  tagVariant,
  description,
}: InstructionSectionType) {
  const theme = useTheme()

  return (
    <MainContainerStyled>
      <Grid.Container
        flexDirection="row"
        alignItems="center"
        gap={theme.spacings.xs3}
        marginBottom={theme.spacings.xs4}
      >
        <ContainerStyled>
          <Icons name={icon} width={16} height={16} />
          <Typography
            variant="body4"
            weight={700}
            variantColor={theme.colors.neutral20}
          >
            {title}
          </Typography>
        </ContainerStyled>
        <Tag variant={tagVariant} as="label">
          {tag}
        </Tag>
      </Grid.Container>
      {!!description?.length && (
        <DescriptionContainerStyled data-section={section}>
          {description?.map((item) => (
            <Typography
              key={item}
              variant="caption"
              weight={400}
              variantColor={theme.colors.neutral40}
            >
              • {item}
            </Typography>
          ))}
        </DescriptionContainerStyled>
      )}
    </MainContainerStyled>
  )
}
