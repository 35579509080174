import Grid, { GridProps } from '@mui/material/Grid'
import React from 'react'
import { useTheme } from 'styled-components'

export interface IInputRootProps extends GridProps {}

export const InputRoot: React.FC<IInputRootProps> = ({ children, ...rest }) => {
  const theme = useTheme()
  return (
    <Grid container direction={'column'} rowGap={theme.spacings.xs2} {...rest}>
      {children}
    </Grid>
  )
}
