import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2'
import Grid from '@mui/material/Grid'
import React from 'react'
import styled from 'styled-components'

const StyledBox = styled(Grid)`
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.xs}`};
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
  border-radius: ${({ theme }) => {
    const { borders } = theme
    return `${borders.radius.s} ${borders.radius.xl} ${borders.radius.s} ${borders.radius.s}}`
  }};
`
export const CheckoutMethodSkeleton = () => {
  return (
    <React.Fragment>
      <StyledBox
        container
        flexDirection={'row'}
        gap={'12px'}
        alignItems={'center'}
        flexWrap={'nowrap'}
      >
        <Skeleton height={40} width={40} variant="circular" />
        <Grid container flexDirection={'column'} gap={'8px'}>
          <Skeleton height={16} width={'161px'} variant="rounded" />
          <Skeleton height={12} width={'90%'} variant="rounded" />
          <Skeleton height={12} width={'20%'} variant="rounded" />
        </Grid>
      </StyledBox>

      <StyledBox
        container
        flexDirection={'row'}
        gap={'12px'}
        alignItems={'center'}
        flexWrap={'nowrap'}
      >
        <Skeleton height={40} width={40} variant="circular" />
        <Grid container flexDirection={'column'} gap={'8px'}>
          <Skeleton height={16} width={'161px'} variant="rounded" />
          <Skeleton height={12} width={'90%'} variant="rounded" />
          <Skeleton height={12} width={'20%'} variant="rounded" />
        </Grid>
      </StyledBox>

      <StyledBox
        container
        flexDirection={'row'}
        gap={'12px'}
        alignItems={'center'}
        flexWrap={'nowrap'}
      >
        <Skeleton height={40} width={40} variant="circular" />
        <Grid container flexDirection={'column'} gap={'8px'}>
          <Skeleton height={16} width={'161px'} variant="rounded" />
          <Skeleton height={12} width={'90%'} variant="rounded" />
          <Skeleton height={12} width={'20%'} variant="rounded" />
        </Grid>
      </StyledBox>
    </React.Fragment>
  )
}
