import styled from 'styled-components'

export const BoxAdornment = styled.div<{
  checked: boolean
  disabled: boolean
  width?: string
  height?: string
}>`
  display: flex;
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.xs}`};
  align-items: center;
  align-self: stretch;
  background: ${({ checked, disabled, theme }) =>
    disabled
      ? theme.colors.neutral95
      : checked
        ? theme.colors.secondary99
        : theme.colors.neutral100};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    box-shadow: ${({ disabled, theme }) =>
      disabled
        ? 'none'
        : `0px ${theme.spacings.xs5} ${theme.spacings.xs} 0px ${theme.colors.secondary99}`};
  }

  border: ${({ checked, disabled, theme }) =>
    `${theme.borders.width.xs2} solid ${
      checked && !disabled ? theme.colors.secondary80 : theme.colors.neutral90
    }`};
  border-radius: ${({ theme }) => `${theme.borders.radius.s}`};
`
