import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'
import { OrderConfirmationCard } from 'src/components/order-confirmation-card'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

import { IDueDateInfo } from '../../order-infos/due-date-info.interface'

export const PixDueDateInfo: React.FC<IDueDateInfo> = ({ dueDate }) => {
  const dueDateString = dayjs(dueDate).format('DD/MM/YYYY')

  return (
    <OrderConfirmationCard.Content.Info
      label={t(
        `order.confirmation.cardGrid.infos.dueDate.${PaymentMethodEnum.PIX}`,
      )}
      value={dueDateString}
      variant={'secondary'}
    />
  )
}
