import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

export interface ITextTitle {
  children: string
}

export const TextTitle: React.FC<ITextTitle> = (props) => {
  const theme = useTheme()
  return (
    <Typography variant="headline8" variantColor={theme.colors.neutral20}>
      {props.children}
    </Typography>
  )
}
