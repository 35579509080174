import Grid from '@mui/material/Grid'
import React from 'react'

interface IGridItemProps
  extends Omit<React.ComponentProps<typeof Grid>, 'item'> {}

export const GridItem: React.FC<IGridItemProps> = ({ children, ...props }) => {
  return (
    <Grid item {...props}>
      {children}
    </Grid>
  )
}
