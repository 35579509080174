import Grid from '@mui/material/Grid'
import React from 'react'
import { useTheme } from 'styled-components'

interface ICardGridProps extends React.ComponentProps<typeof Grid> {}

export const CardGrid: React.FC<ICardGridProps> = ({ children, ...props }) => {
  const theme = useTheme()

  return (
    <Grid
      display={'flex'}
      p={{ xl: theme.spacings.m }}
      flexDirection={'column'}
      rowGap={theme.spacings.s}
      borderRadius={theme.borders.radius.m}
      border={{
        xl: `${theme.borders.width.xs2} solid ${theme.colors.neutral90}`,
      }}
      {...props}
    >
      {children}
    </Grid>
  )
}
