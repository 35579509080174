import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'
import { useTheme } from 'styled-components'

export type ICutoffTimeProps = React.ComponentProps<
  typeof Checkout.Content.Input.TimePicker
>

export const CutoffTime: React.FC<ICutoffTimeProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Checkout.Content.Input.Root
      item
      sm={12}
      xl={6}
      pr={{ xl: theme.spacings.xs3 }}
    >
      <Checkout.Content.Input.TimePicker
        {...props}
        label={t(
          'order.checkoutDates.cutoffDate.cutoffTimeInput.inputPlaceholder',
        )}
      />
    </Checkout.Content.Input.Root>
  )
}
