import { useCallback, useEffect, useRef, useState } from 'react'
import { useCurrencyInput } from 'src/utils/hooks/use_currency_input'

import { BenefitAssignment } from '../../../context'
import { useEditAssignmentsModal } from '../../../hooks/use-edit-assignments-modal'

type UseBenefitEditProps = {
  benefit: BenefitAssignment
}

export function useBenefitEdit({ benefit }: UseBenefitEditProps) {
  const [isFieldEnabled, setIsFieldEnabled] = useState(false)
  const { updateBenefit, mode } = useEditAssignmentsModal()

  const {
    handleInputChange,
    valueInCurrency,
    value: newValue,
  } = useCurrencyInput(benefit.value)

  const lastReportedValue = useRef(newValue)
  const lastReportedFieldEnabled = useRef(false)

  const toggleFieldState = useCallback(() => {
    setIsFieldEnabled((prev) => !prev)
  }, [])

  useEffect(() => {
    if (lastReportedValue.current !== newValue) {
      updateBenefit(benefit, newValue)

      lastReportedValue.current = newValue
    }
  }, [benefit, newValue, updateBenefit])

  useEffect(() => {
    if (lastReportedFieldEnabled.current !== isFieldEnabled) {
      updateBenefit(benefit, isFieldEnabled ? newValue : null)

      lastReportedFieldEnabled.current = isFieldEnabled
    }
  }, [benefit, isFieldEnabled, newValue, updateBenefit])

  return {
    toggleFieldState,
    isFieldEnabled,
    valueInCurrency,
    handleInputChange,
    mode,
  }
}
