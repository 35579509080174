import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

export interface ITextSubtitle {
  children: string
}

export const TextSubtitle: React.FC<ITextSubtitle> = (props) => {
  const theme = useTheme()
  return (
    <Typography variant="body3" variantColor={theme.colors.neutral50}>
      {props.children}
    </Typography>
  )
}
