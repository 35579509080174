import React from 'react'

import { ICheckoutMethod } from '../../checkout-method.interface'
import { CheckoutDatesInputs } from '../dates-inputs'
import { IPixDueDateProps, PixDueDate } from './pix-due-date'

export interface IPixCheckoutProps extends ICheckoutMethod {}

export const PixCheckout: React.FC<IPixCheckoutProps> = ({
  disabled = false,
  onDepositDateChange,
  depositDate,
  dueDate,
  paymentMethod,
}) => {
  const DueDateInput: React.ReactElement<IPixDueDateProps> = React.useMemo(
    () => (
      <PixDueDate value={dueDate} disabled={true} onDateChange={() => {}} />
    ),
    [dueDate],
  )

  return (
    <CheckoutDatesInputs
      disabled={disabled}
      value={depositDate}
      onDateChange={onDepositDateChange}
      dueDateInput={DueDateInput}
      paymentMethod={paymentMethod}
    />
  )
}
