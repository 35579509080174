import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components'

interface IDescription {
  children: ReactNode
}

export const Description = ({ children }: IDescription) => {
  const theme = useTheme()

  return (
    <Typography variant="body3" variantColor={theme.colors.neutral50}>
      {children}
    </Typography>
  )
}
