import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2'
import { Emoji } from 'src/components/emoji'
import { useTheme } from 'styled-components'

import { BenefitAssignment } from '../../context'
import { TextField } from './components/text-field'
import { useBenefitEdit } from './hooks/use-benefit-edit'
import { BenefitEditWrapperStyled, TypographyStyled } from './styles'

type BenefitEditProps = {
  benefit: BenefitAssignment
}

export function BenefitEdit({ benefit }: BenefitEditProps) {
  const {
    isFieldEnabled,
    toggleFieldState,
    valueInCurrency,
    handleInputChange,
    mode,
  } = useBenefitEdit({ benefit })
  const theme = useTheme()

  return (
    <BenefitEditWrapperStyled>
      <div>
        <Checkbox checked={isFieldEnabled} onChange={toggleFieldState} />

        <div>
          <Emoji imgSrc={benefit.emoji} width={40} />

          <TypographyStyled
            variant="body3"
            weight={600}
            variantColor={theme.colors.neutral[30]}
          >
            {benefit.name}
          </TypographyStyled>
        </div>
      </div>

      <TextField
        value={valueInCurrency}
        onChange={handleInputChange}
        depositTimeUnit={benefit.depositTimeUnit}
        isFieldEnabled={isFieldEnabled}
        mode={mode}
      />
    </BenefitEditWrapperStyled>
  )
}
