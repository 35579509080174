import { Tag, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { EmojiStackContainerStyled, NoEmojiIconStyled } from './styles'

type Emoji = {
  url: string
  name: string
}

type EmojiStackProps = {
  emojis: Emoji[]
  boxShadowColor?: string
  noEmojisMessage: string
}

function formatTooltipContent(icons: Emoji[]) {
  return icons.map(({ name }, key) => <p key={key}>{name}</p>)
}

export function EmojiStack({
  emojis,
  boxShadowColor,
  noEmojisMessage,
}: EmojiStackProps) {
  const theme = useTheme()

  const extraEmojiTooltip = useMemo(
    () => formatTooltipContent(emojis.slice(4)),
    [emojis],
  )

  if (!emojis.length) {
    return (
      <Tag variant="yellow" as="span">
        <NoEmojiIconStyled
          name="IconCircleDotted"
          fill={theme.colors.feedback.error10}
          size={16}
        />{' '}
        <Typography
          variant="body4"
          weight={600}
          color={theme.colors.feedback.error10}
        >
          {noEmojisMessage}
        </Typography>
      </Tag>
    )
  }

  return (
    <EmojiStackContainerStyled boxShadowColor={boxShadowColor}>
      {emojis.slice(0, 4).map(({ name, url }, key) => (
        <Tooltip key={key} title={name}>
          <div className="icon">
            <img src={url} alt={name} />
          </div>
        </Tooltip>
      ))}

      {emojis.length > 4 && (
        <Tooltip title={extraEmojiTooltip}>
          <div className="icon">
            <Typography
              variant="caption"
              variantColor={theme.colors.neutral40}
              weight={700}
            >
              +{emojis.length - 4}
            </Typography>
          </div>
        </Tooltip>
      )}
    </EmojiStackContainerStyled>
  )
}
