import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type Row<TSubRow> = {
  id: string
  subRows?: TSubRow[]
} & Record<string, unknown>

type UseRowAndSubRowSelectionProps<TSubRow, TRow extends Row<TSubRow>> = {
  data: TRow[]
  getSubRowId: (subRow: TSubRow) => string
  getSubRows?: (row: TRow) => TSubRow[]
  initialSelectedRows?: string[]
  initialSelectedSubRows?: string[]
}

export function useRowAndSubRowSelection<TSubRow, TRow extends Row<TSubRow>>({
  data,
  getSubRowId,
  getSubRows = (row) => row?.subRows ?? [],
  initialSelectedRows = [],
  initialSelectedSubRows = [],
}: UseRowAndSubRowSelectionProps<TSubRow, TRow>) {
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set())
  const [selectedSubRows, setSelectedSubRows] = useState<Set<string>>(new Set())
  const [selectAll, setSelectAll] = useState(false)

  const hasInitialized = useRef(false)

  // Verifica se todos os rows e subRows estão selecionados
  const areAllSelected = useMemo(() => {
    return data.every((row) => {
      const subRows = getSubRows(row)

      if (subRows.length > 0) {
        return subRows.every((subRow) =>
          selectedSubRows.has(getSubRowId(subRow)),
        )
      }

      return selectedRows.has(row.id)
    })
  }, [data, getSubRows, selectedRows, selectedSubRows, getSubRowId])

  // Verifica se o header está no estado indeterminate
  const isHeaderIndeterminate = useMemo(() => {
    const hasSelectableRows = data.some(
      (row) => getSubRows(row).length > 0 || true,
    )

    const hasSelection = selectedRows.size > 0 || selectedSubRows.size > 0

    return hasSelectableRows && hasSelection && !areAllSelected
  }, [
    data,
    selectedRows.size,
    selectedSubRows.size,
    areAllSelected,
    getSubRows,
  ])

  // Verifica se uma row está totalmente selecionada
  const isRowSelected = useCallback(
    (rowId: string) => {
      const row = data.find((row) => row.id === rowId)
      const subRows = row ? getSubRows(row) : []

      if (subRows.length > 0) {
        return subRows.every((subRow) =>
          selectedSubRows.has(getSubRowId(subRow)),
        )
      }

      return selectedRows.has(rowId)
    },
    [data, getSubRows, selectedRows, selectedSubRows, getSubRowId],
  )

  // Verifica se uma row está parcialmente selecionada (indeterminate)
  const isRowIndeterminate = useCallback(
    (rowId: string) => {
      const row = data.find((row) => row.id === rowId)
      const subRows = row ? getSubRows(row) : []

      if (subRows.length === 0) {
        return false
      }

      const isSomeSelected = subRows.some((subRow) =>
        selectedSubRows.has(getSubRowId(subRow)),
      )

      const isAllSelected = subRows.every((subRow) =>
        selectedSubRows.has(getSubRowId(subRow)),
      )

      return isSomeSelected && !isAllSelected
    },
    [data, getSubRows, selectedSubRows, getSubRowId],
  )

  // Alterna a seleção de todas as rows e subRows
  const toggleSelectAll = useCallback(() => {
    if (selectAll || areAllSelected) {
      setSelectedRows(new Set())
      setSelectedSubRows(new Set())
    } else {
      const allRows = new Set(data.map((row) => row.id))

      const allSubRows = new Set(
        data.flatMap((row) =>
          getSubRows(row).map((subRow) => getSubRowId(subRow)),
        ),
      )

      setSelectedRows(allRows)
      setSelectedSubRows(allSubRows)
    }

    setSelectAll((prev) => !prev)
  }, [selectAll, areAllSelected, data, getSubRows, getSubRowId])

  // Alterna a seleção de uma row e suas subRows
  const toggleRowSelection = useCallback(
    (rowId: string) => {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows)
        const row = data.find((row) => row.id === rowId)
        const subRows = row ? getSubRows(row) : []

        if (newSelectedRows.has(rowId)) {
          newSelectedRows.delete(rowId)
        } else {
          newSelectedRows.add(rowId)
        }

        if (subRows.length > 0) {
          setSelectedSubRows((prevSelectedSubRows) => {
            const newSelectedSubRows = new Set(prevSelectedSubRows)
            const subRowIds = subRows.map((subRow) => getSubRowId(subRow))
            if (newSelectedRows.has(rowId)) {
              subRowIds.forEach((subRowId) => newSelectedSubRows.add(subRowId))
            } else {
              subRowIds.forEach((subRowId) =>
                newSelectedSubRows.delete(subRowId),
              )
            }
            return newSelectedSubRows
          })
        }

        return newSelectedRows
      })
    },
    [data, getSubRowId, getSubRows],
  )

  // Alterna a seleção de uma subRow
  const toggleSubRowSelection = useCallback(
    (subRowId: string, rowId: string) => {
      setSelectedSubRows((prevSelectedSubRows) => {
        const newSelectedSubRows = new Set(prevSelectedSubRows)
        if (newSelectedSubRows.has(subRowId)) {
          newSelectedSubRows.delete(subRowId)
        } else {
          newSelectedSubRows.add(subRowId)
        }

        const row = data.find((row) => row.id === rowId)
        const allSubRowIds = row
          ? getSubRows(row).map((subRow) => getSubRowId(subRow))
          : []

        const isRowFullySelected = allSubRowIds.every((id) =>
          newSelectedSubRows.has(id),
        )

        setSelectedRows((prevSelectedRows) => {
          const newSelectedRows = new Set(prevSelectedRows)
          if (isRowFullySelected) {
            newSelectedRows.add(rowId)
          } else {
            newSelectedRows.delete(rowId)
          }
          return newSelectedRows
        })

        return newSelectedSubRows
      })
    },
    [data, getSubRowId, getSubRows],
  )

  const memorizationSelectedRows = useMemo(
    () => Array.from(selectedRows),
    [selectedRows],
  )

  const memorizationSelectedSubRows = useMemo(
    () => Array.from(selectedSubRows),
    [selectedSubRows],
  )

  useEffect(() => {
    if (data.length && !hasInitialized.current) {
      if (selectedRows.size === 0 && initialSelectedRows.length) {
        setSelectedRows(new Set(initialSelectedRows))
      }

      if (selectedSubRows.size === 0 && initialSelectedSubRows.length) {
        setSelectedSubRows(new Set(initialSelectedSubRows))
      }

      hasInitialized.current = true
    }
  }, [
    data,
    initialSelectedRows,
    initialSelectedSubRows,
    selectedRows.size,
    selectedSubRows.size,
  ])

  return {
    selectedRows: memorizationSelectedRows,
    selectedSubRows: memorizationSelectedSubRows,
    selectAll,
    isHeaderIndeterminate,
    isRowSelected,
    isRowIndeterminate,
    areAllSelected,
    toggleSelectAll,
    toggleRowSelection,
    toggleSubRowSelection,
  }
}
