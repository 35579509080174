import { DropzoneFile } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types'
import { useCallback, useEffect, useState } from 'react'
import { trpc } from 'src/api/client'
import { useToast } from 'src/utils/hooks/useToast'

import { useOrderBySpreadsheetStore } from '../../../store/use-order-by-spreadsheet-store'

export function useFlashSpreadsheet() {
  const { toastWarning, toastError, toastSuccess } = useToast()
  const [isUploadingSpreadsheet, setIsUploadingSpreadsheet] = useState(false)
  const { droppedFile, setDroppedFile, setContinueButtonOptions } =
    useOrderBySpreadsheetStore()

  const {
    data: spreadsheetPath,
    isLoading: isLoadingSpreadsheetPath,
    isError: isErrorSpreadsheetPath,
  } = trpc.benefitOrders.getCompanySpreadsheet.useQuery()

  const {
    mutate: fetchUploadUrl,
    data: uploadUrlData,
    isLoading: isFetchingUploadUrl,
  } = trpc.benefitOrders.createSpreadsheetUploadUrl.useMutation({
    onError: () => {
      toastError({
        title: 'Não foi possível processar arquivo enviado',
        description: 'Por favor, remova o arquivo e envie novamente.',
      })
    },
  })

  const handleFileChange = (files: DropzoneFile[]) => {
    const file = files?.[0]?.file ?? null

    setDroppedFile(file)

    if (file && (!uploadUrlData?.signedUrl || !uploadUrlData?.fileId)) {
      fetchUploadUrl()
    }
  }

  const uploadFile = useCallback(
    async (signedUrl: string, file: File) => {
      setIsUploadingSpreadsheet(true)

      try {
        await fetch(signedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        })

        toastSuccess({
          title: 'Arquivo enviado com sucesso',
        })
      } catch (error) {
        toastError({
          title: 'Não foi possível realizar o envio do arquivo',
          description:
            'Tente novamente mais tarde, ou entre em contato com a nossa Central de Ajuda.',
        })
      } finally {
        setIsUploadingSpreadsheet(false)
      }
    },
    [toastError, toastSuccess],
  )

  const handleUploadSpreadsheet = useCallback(async () => {
    if (!droppedFile) {
      toastWarning({
        title: 'Faça o envio do arquivo para continuar',
        description:
          'É necessário enviar o arquivo preenchido com as informações do pedido de benefício para continuar',
      })

      return
    }

    const { signedUrl } = uploadUrlData || {}

    if (signedUrl) {
      await uploadFile(signedUrl, droppedFile)
    }
  }, [droppedFile, toastWarning, uploadFile, uploadUrlData])

  useEffect(() => {
    setContinueButtonOptions({
      text: 'Continuar',
      onClick: handleUploadSpreadsheet,
      isLoading: isFetchingUploadUrl || isUploadingSpreadsheet,
    })
  }, [
    handleUploadSpreadsheet,
    isFetchingUploadUrl,
    isUploadingSpreadsheet,
    setContinueButtonOptions,
  ])

  useEffect(() => {
    if (isErrorSpreadsheetPath) {
      toastError({
        title: 'Não foi possível obter o modelo de planilha',
        description:
          'Caso precise baixar o modelo de planilha, por favor, recarregue a página e tente novamente.',
      })
    }
  }, [isErrorSpreadsheetPath, toastError])

  return {
    handleFileChange,
    isLoadingSpreadsheetPath,
    spreadsheetPath: spreadsheetPath?.path,
  }
}
