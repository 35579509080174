import { useTranslation } from 'react-i18next'

import { InstructionSectionType } from '../instruction-section'

export const buildAccordionText = (): InstructionSectionType[] => {
  const { t } = useTranslation()

  const translatePrefix = 'newOrder.flashSpreadsheet.accordion.instructions'

  return [
    {
      title: t(`${translatePrefix}.company.title`),
      section: 'company',
      icon: 'IconBuildingSkyscraper',
      tag: t(`${translatePrefix}.company.tag`),
      tagVariant: 'primary',
      description: t(`${translatePrefix}.company.description`, {
        returnObjects: true,
      }) as [],
    },
    {
      title: t(`${translatePrefix}.document.title`),
      section: 'document',
      icon: 'IconId',
      tag: t(`${translatePrefix}.document.tag`),
      tagVariant: 'primary',
      description: t(`${translatePrefix}.document.description`, {
        returnObjects: true,
      }) as [],
    },
    {
      title: t(`${translatePrefix}.benefitAmount.title`),
      section: 'benefit_amount',
      icon: 'IconPaperBag',
      tag: t(`${translatePrefix}.benefitAmount.tag`),
      tagVariant: 'primary',
      description: t(`${translatePrefix}.benefitAmount.description`, {
        returnObjects: true,
      }) as [],
    },
    {
      title: t(`${translatePrefix}.name.title`),
      section: 'name',
      icon: 'IconUser',
      tag: t(`${translatePrefix}.name.tag`),
      tagVariant: 'gray',
      description: t(`${translatePrefix}.name.description`, {
        returnObjects: true,
      }) as [],
    },
    {
      title: t(`${translatePrefix}.email.title`),
      section: 'email',
      icon: 'IconMail',
      tag: t(`${translatePrefix}.email.tag`),
      tagVariant: 'gray',
      description: t(`${translatePrefix}.email.description`, {
        returnObjects: true,
      }) as [],
    },
    {
      title: t(`${translatePrefix}.phone.title`),
      section: 'phone',
      icon: 'IconDeviceMobile',
      tag: t(`${translatePrefix}.phone.tag`),
      tagVariant: 'gray',
      description: t(`${translatePrefix}.phone.description`, {
        returnObjects: true,
      }) as [],
    },
  ]
}
