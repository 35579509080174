import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import { useTheme } from 'styled-components'

export type IDepositDateProps = React.ComponentProps<
  typeof Checkout.Content.Input.DatePicker
>

export const DepositDate: React.FC<IDepositDateProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { trackEvent } = useEventTracking()

  const onClick = () => {
    trackEvent({
      name: 'FlashOS_BenefitOrders_Checkout_Order_AvailabilityDate_Click',
    })
  }
  return (
    <Checkout.Content.Input.Root
      item
      sm={12}
      xl={6}
      pr={{ xl: theme.spacings.xs3 }}
    >
      <Checkout.Content.Input.Label>
        {t('order.checkoutDates.availabilityDate.depositDateInput.label')}
      </Checkout.Content.Input.Label>
      <Checkout.Content.Input.DatePicker
        {...props}
        label={t(
          'order.checkoutDates.availabilityDate.depositDateInput.inputPlaceholder',
        )}
        placeholder={t(
          'order.checkoutDates.availabilityDate.depositDateInput.format',
        )}
        onClick={onClick}
      />
    </Checkout.Content.Input.Root>
  )
}
