import React from 'react'
import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'
import { IBalances } from 'src/components/checkout/components/balance/balance-tag'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

export const isBalanceInsufficient = (
  balance: IBalances,
  totalAmount: number,
) => {
  return totalAmount > balance.value
}

export interface IFlashCashBalancesProps {
  balances: IBalances[]
  totalAmount: number
}

export const FlashCashBalances: React.FC<IFlashCashBalancesProps> = ({
  balances,
  totalAmount,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Checkout.Content.Balances.Root>
      <Checkout.Content.Balances.Title>
        {t('order.balances.title')}
      </Checkout.Content.Balances.Title>
      <Grid.Item container direction={'row'} gap={theme.spacings.xl3}>
        {balances.map((tag) => (
          <Checkout.Content.Balances.Tag
            key={`balance-tag-${tag.label}`}
            {...tag}
            variant={
              isBalanceInsufficient(tag, totalAmount) ? 'error' : 'green'
            }
          />
        ))}
      </Grid.Item>
    </Checkout.Content.Balances.Root>
  )
}
