import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useOrderLinks } from './hooks/use-order-links'
import { LinkButtonsContainer, OrderLinksContainer } from './styles'

export interface IOrderLinksProps {
  openNewTab: (_string: string) => void
}

export const OrderLinks: React.FC<IOrderLinksProps> = () => {
  const { t } = useTranslation()
  const { onClickOrderDetails, generateReport, isLoadingReport } =
    useOrderLinks()

  return (
    <OrderLinksContainer>
      <LinkButtonsContainer>
        <LinkButton
          variant="neutral"
          size="medium"
          onClick={onClickOrderDetails}
        >
          {t('newOrder.orderCompletion.navigateOrderDeposits')}{' '}
          <Icons name="IconExternalLink" size={16} />
        </LinkButton>
      </LinkButtonsContainer>
      <Button
        size={'small'}
        variant="secondary"
        onClick={generateReport}
        loading={isLoadingReport}
      >
        {t('newOrder.orderCompletion.generateReport')}
      </Button>
    </OrderLinksContainer>
  )
}
