import { useEffect } from 'react'
import { Focused } from 'src/components/focused'

import { useNewOrderPage } from './hooks/use-new-order-page'
import { useOrderBySpreadsheetStore } from './store/use-order-by-spreadsheet-store'

export default function NewOrderPage() {
  const { activeStep, StepRender, breadcrumb, startActions, endActions } =
    useNewOrderPage()

  const { resetStore } = useOrderBySpreadsheetStore()

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [resetStore])

  return (
    <Focused
      activeStep={activeStep}
      breadcrumb={breadcrumb}
      steps={[]}
      footer={{
        startActions,
        endActions,
      }}
    >
      <StepRender />
    </Focused>
  )
}
