import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2'

import { Employee, Group } from '../types'

type SelectionCheckboxCellProps = {
  groupOrEmployee: Group | Employee
  selectedEmployeeIds: string[]
  isRowSelected?: (rowId: string) => boolean
  isRowIndeterminate?: (rowId: string) => boolean
  toggleRowSelection?: (rowId: string) => void
  toggleEmployeeSelection?: (subRowId: string, rowId: string) => void
}

export function SelectionCheckboxCell({
  groupOrEmployee,
  isRowSelected,
  isRowIndeterminate,
  toggleRowSelection,
  selectedEmployeeIds,
  toggleEmployeeSelection,
}: SelectionCheckboxCellProps) {
  if ((groupOrEmployee as Employee)?.document && toggleEmployeeSelection) {
    const employee = groupOrEmployee as Employee

    return (
      <Checkbox
        checked={selectedEmployeeIds.includes(employee.id)}
        onChange={() => toggleEmployeeSelection(employee.id, employee.groupId)}
      />
    )
  }

  const group = groupOrEmployee as Group

  const isFullySelected = isRowSelected && isRowSelected(group.id)
  const isIndeterminate = isRowIndeterminate && isRowIndeterminate(group.id)

  return (
    <Checkbox
      checked={isFullySelected}
      indeterminate={isIndeterminate}
      onChange={() => toggleRowSelection && toggleRowSelection(group.id)}
      disabled={group.employees.length === 0}
    />
  )
}
