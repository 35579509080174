import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'
import { useTheme } from 'styled-components'

export type ICutoffDateProps = React.ComponentProps<
  typeof Checkout.Content.Input.DatePicker
>

export const CutoffDate: React.FC<ICutoffDateProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Checkout.Content.Input.Root
      item
      sm={12}
      xl={6}
      pr={{ xl: theme.spacings.xs3 }}
    >
      <Checkout.Content.Input.DatePicker
        {...props}
        label={t(
          'order.checkoutDates.cutoffDate.cutoffDateInput.inputPlaceholder',
        )}
        placeholder={t('order.checkoutDates.cutoffDate.cutoffDateInput.format')}
      />
    </Checkout.Content.Input.Root>
  )
}
