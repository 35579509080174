import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'

export type IPixDueDateProps = React.ComponentProps<
  typeof Checkout.Content.Input.DatePicker
>

export const PixDueDate: React.FC<IPixDueDateProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Checkout.Content.Input.Root xs>
      <Checkout.Content.Input.Label>
        {t('order.checkoutDates.availabilityDate.pixDueDateInput.label')}
      </Checkout.Content.Input.Label>
      <Checkout.Content.Input.DatePicker
        id="pixDueDateInput"
        label={t(
          'order.checkoutDates.availabilityDate.pixDueDateInput.inputPlaceholder',
        )}
        {...props}
      />
    </Checkout.Content.Input.Root>
  )
}
